import React from 'react';
import Container from 'react-bootstrap/Container';
import { connect } from 'react-redux';
import {
    fetchRestaurant,
    displayModal,
    displayModalCart,
    hideModalCart,
    fetchMenus,
    fetchDish,
    selectDish,
    setChecked,
    setQuantity,
    setTotalPrice,
    setCart,
    setCartIndex,
    fetchTags,
    selectMenuTag
} from "../actions";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import './Restaurant.css';
import Button from 'react-bootstrap/Button';
import shoppingCart from '../assets/image/shoppingCart.svg';
import checked from '../assets/image/checkbox-cart-checked.svg';
import unchecked from '../assets/image/checkbox-cart-unchecked.svg';
import closeX from '../assets/image/closeX.svg';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Translation } from "react-i18next";
import Config from '../utility/Config';

/**
 * Restaurant Menu component.
 */
class RestaurantMenu extends React.Component {

    /**
     * Constructor
     * @param {*} props to set.
     */
    constructor(props) {
        super(props);
        this.state = {
            categoriesFailed: {},
        };
    }

    /**
     * method to open cart modal
     * @param {*} id
     */
    openCartModal = async (id) => {
        this.props.selectDish(id);
        await this.props.fetchDish(id);
        this.props.setCartIndex(-1);
        this.updateTotalPrice();
        this.props.displayModalCart();
        this.setState({categoriesFailed: {}});
    };

    /**
     * method that updates the total price
     */
    updateTotalPrice() {
        if (this.props.selectedDish.hasOwnProperty('dish')) {
            var tot = this.props.selectedDish.dish.dishPrice;
            this.props.selectedDish.addonCategories.forEach(category => {
                category.addon.forEach(add => {
                    if (this.props.cartModal.checkedItems.includes(add.id)) {
                        tot += add.addonPrice;
                    }
                })
            });
            tot = tot * this.props.cartModal.quantity;
            if (this.props.cartModal.totalPrice !== tot)
                this.props.setTotalPrice(tot.toFixed(2));
        }
    }

    componentDidMount() {
        this.props.fetchMenus(this.props.id);
        this.props.fetchRestaurant(this.props.id);
        this.props.fetchTags();
    }

    /**
     * method that decrease quantity
     */
    async increaseQuantity() {
        await this.props.setQuantity(this.props.cartModal.quantity + 1);
        this.updateTotalPrice();
    }

    /**
     * method that increase quantity
     */
    async decreaseQuantity() {
        if (this.props.cartModal.quantity > 1) {
            await this.props.setQuantity(this.props.cartModal.quantity - 1);
            this.updateTotalPrice();
        }
    }

    /**
     * Validate addons of addon given category during checkbox toggle.
     * @param cat the given category to check.
     * @param val the val of checkbox.
     * @param isSubmit is the action form submit or checkbox toggle action.
     * @returns {boolean} if addons validation passed or not.
     */
    validateAddonCategory = (cat, val, isSubmit = false) => {
        let selectedAddonCount = 0;
        var {categoriesFailed} = this.state;
        for (let i = 0; i < cat.addon.length; i++) {
            if (this.props.cartModal.checkedItems.includes(cat.addon[i].id) === true) {
                selectedAddonCount++;
            }
        }

        if (!isSubmit) {
            if (cat.isMandatory && cat.maxAddons !== 99999 && selectedAddonCount === cat.maxAddons && val) {
                categoriesFailed[cat.categoryName] = {
                    transKey: 'cannot.select.more.than.x',
                };
                this.setState({categoriesFailed: categoriesFailed});
                return false;
            }
            if (!val) {
                categoriesFailed[cat.categoryName] = undefined;
                categoriesFailed = JSON.parse(JSON.stringify(categoriesFailed));
                this.setState({categoriesFailed: categoriesFailed});
            }
            return true;
        } else {
            if (cat.isMandatory) {
                if (!(selectedAddonCount >= cat.minAddons && selectedAddonCount <= cat.maxAddons)) {
                    if (cat.minAddons === cat.maxAddons) {
                        categoriesFailed[cat.categoryName] = {
                            transKey: 'must.select.x',
                        };
                    } else if (selectedAddonCount < cat.minAddons) {
                        categoriesFailed[cat.categoryName] = {
                            transKey: 'at.least.x',
                        };
                    }
                    this.setState({categoriesFailed: categoriesFailed});
                    return false;
                }
            }
            return true;
        }
    };

    /**
     * Validates all addon categories when proceed button pressed.
     * @returns {boolean}
     */
    validateAllAddonCategories = () => {
        var dishAddonCategories = this.props.selectedDish.addonCategories;
        var isAllAddonsValid = true;
        for (var i = 0; i < dishAddonCategories.length; i++) {
            var addonCat = dishAddonCategories[i];
            if (!this.validateAddonCategory(addonCat, null, true)) {
                isAllAddonsValid = false;
            }
        }
        return isAllAddonsValid;
    };

    /**
     * method that handle the checkboxes
     * @param {*} cat
     * @param {*} id
     * @param {*} val
     */
    setToggleCheckBox(cat, id, val) {
        if (this.validateAddonCategory(cat, val, false)) {
            this.setState({categoriesFailed: {}});
            let array = this.props.cartModal.checkedItems;
            if (val) {
                array.push(id);
                this.props.setChecked(array);
            } else {
                const index = array.indexOf(id);
                if (index > -1) {
                    array.splice(index, 1);
                }
                this.props.setChecked(array);
            }
            this.updateTotalPrice();
        }
    }

    /**
     * method to add dish to cart
     * @param {*} id
     */
    addToCart(id) {
        this.setState({categoriesFailed: {}});
        var baseJson = {
            'dish': this.props.selectedDish,
            'modal': this.props.cartModal
        };
        var content = this.props.cart.cartContent.slice();

        if (this.validateAllAddonCategories()) {
            if (this.props.cartModal.cartIndex === -1) {
                content.push(baseJson);
                this.props.setCart(content);
            } else {
                content.splice(this.props.cartModal.cartIndex, 1, baseJson);
                this.props.setCart(content);
            }
            this.closeModalCart();
        }
    }

    /**
     * method to close cart modal
     */
    async closeModalCart() {
        this.props.hideModalCart();
        this.props.setQuantity(1);
        this.props.setChecked(new Array());
        this.props.setTotalPrice(0);
        this.setState({categoriesFailed: {}});
    }

    render() {
        return (
            this.props.restaurantMenus.menus != null && Array.isArray(this.props.restaurantMenus.menus) ?
                <div>
                    <Col sm={12}>
                        {this.props.selectedDish == null || typeof this.props.selectedDish === 'undefined' || !this.props.selectedDish.hasOwnProperty('dish') ? null :
                            <Modal show={this.props.cartModal.cartModalIsOpen} onHide={() => this.closeModalCart()} dialogClassName={'cart-modal'} size='lg'>
                                {this.props.selectedDish !== undefined ?
                                    <Modal.Body>
                                        <Translation ns="restaurant.page">
                                            {t =>
                                                <Container>
                                                    <Row>
                                                        <Col md={12} style={{ textAlign: 'right', cursor: 'pointer' }}>
                                                            <div onClick={() => this.closeModalCart()}>
                                                                <Image src={closeX} style={{ height: '50px' }}/>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={12} lg={6}>
                                                            <Row>
                                                                <Col xs={7} sm={8} md={8} lg={8} className='pr-0'>
                                                                    <h4 className={'dish-name'} style={{ color: 'rgb(178, 211, 123)', fontFamily: 'MontserratRegular', fontWeight: 'bold', wordBreak: 'break-word' }}>{this.props.selectedDish.dish.dishName}</h4>
                                                                </Col>
                                                                <Col xs={5} sm={4} md={4} lg={4} style={{ textAlign: 'right' }} className='pl-0'>
                                                                    <h4 className={'dish-price'} style={{ color: '#56773a', fontFamily: 'MontserratRegular', fontWeight: 'bold' }}>{this.props.selectedDish.dish.dishPrice.toFixed(2) + " €"}</h4>
                                                                </Col>
                                                            </Row>
                                                            <Image src={this.props.selectedDish.dish.dishPicture} style={{ width: '100%' }} fluid />
                                                            <div style={{ backgroundColor: '#92c143', marginTop: '20px', padding: '10px', paddingBottom: '30px' }}>
                                                                <div style={{ color: 'white', fontFamily: 'MontserratRegular', fontSize: '18px', paddingTop: '20px' }}>{t('ingredients')}</div>
                                                                <div style={{ color: 'white', fontFamily: 'MontserratRegular', fontSize: '18px', paddingBottom: '20px' }}>
                                                                    {this.props.selectedDish.dish.dishDescription}
                                                                </div>
                                                            </div>

                                                        </Col>
                                                        <Col md={12} lg={6}>
                                                            <Col md={12}>
                                                                <p className='personalize' style={{ color: '#56773a', fontWeight: 'bold', fontSize: '16px', marginLeft: '30px', fontFamily: 'MontserratRegular' }}>{t("personalize.product")}</p>
                                                            </Col>
                                                            {this.props.selectedDish.addonCategories.map(cat => {
                                                                return <Row style={{ marginLeft: '0px', marginRight: '0px' }}>
                                                                    <Col sm={12}>
                                                                        {
                                                                            this.state.categoriesFailed.hasOwnProperty(cat.categoryName) ?
                                                                                <p style={{fontSize: 14, color: 'red', marginLeft: '45px', fontFamily: 'MontserratRegular', marginTop: '10px', marginBottom: 0}}>
                                                                                    {t(this.state.categoriesFailed[cat.categoryName].transKey, {minAddon: cat.minAddons, maxAddon: cat.maxAddons })}
                                                                                </p>
                                                                                :
                                                                                null
                                                                        }
                                                                        <p style={{ color: 'black', fontWeight: 'bold', marginLeft: '45px', fontFamily: 'MontserratRegular', marginBottom: 0 }} className='category-addon'>
                                                                            {cat.categoryName}
                                                                            {cat.minAddons > 0 || (cat.maxAddons > 0 && cat.maxAddons !== 99999) ? ' (' : null}
                                                                            {cat.minAddons > 0 ? t('atleast') + cat.minAddons : null}
                                                                            {cat.minAddons > 0 && cat.maxAddons > 0 && cat.maxAddons !== 99999 ? t('and') : null}
                                                                            {cat.maxAddons > 0 && cat.maxAddons !== 99999 ? t('nomore') + cat.maxAddons : null}
                                                                            {cat.minAddons > 0 || (cat.maxAddons > 0 && cat.maxAddons !== 99999) ? '):' : null}
                                                                        </p>
                                                                    </Col>
                                                                    {cat.addon.map(addon => {
                                                                        return <Col md={12}>
                                                                            <Row>
                                                                                <Col style={{ display: 'flex' }} xs={7} sm={6} md={6} lg={8} className='pr-0'>
                                                                                    <span>
                                                                                        {this.props.cartModal.checkedItems.includes(addon.id) ?
                                                                                            <Image src={checked} style={{ height: '25px' }} onClick={() => this.props.cartModal.checkedItems.includes(addon.id) ? this.setToggleCheckBox(cat, addon.id, false) : this.setToggleCheckBox(cat, addon.id, true)} /> :
                                                                                            <Image src={unchecked} style={{ height: '25px' }} onClick={() => this.props.cartModal.checkedItems.includes(addon.id) ? this.setToggleCheckBox(cat, addon.id, false) : this.setToggleCheckBox(cat, addon.id, true)} />}
                                                                                    </span>
                                                                                    <span>
                                                                                        <Form.Check className='addon-checkbox' checked={this.props.cartModal.checkedItems.includes(addon.id)} id={addon.id} type="checkbox" label={<p style={{ color: 'black', fontWeight: 'bold', paddingLeft: '0px', fontFamily: 'MontserratRegular', wordBreak: 'break-word' }}>{addon.addonName}</p>}
                                                                                            onClick={() => this.props.cartModal.checkedItems.includes(addon.id) ? this.setToggleCheckBox(cat, addon.id, false) : this.setToggleCheckBox(cat, addon.id, true)} />
                                                                                    </span>
                                                                                </Col>
                                                                                <Col xs={5} sm={6} md={6} lg={4} className='pl-0'>
                                                                                    {addon.addonPrice !== 0.00 ?
                                                                                        <p style={{ color: 'black', fontWeight: 'bold', textAlign: 'center', fontFamily: 'MontserratRegular' }}>{"+" + addon.addonPrice.toFixed(2) + " €"}</p>
                                                                                        : null
                                                                                    }
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                    })}

                                                                </Row>
                                                            })}
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={6}>
                                                            <Row style={{ marginTop: '30px' }}>
                                                                <Col md={3} xs={3} sm={3} style={{ paddingTop: '10px' }}>
                                                                    <p className={'display-inline'} style={{ marginRight: 5, fontFamily: 'MontserratRegular', color: 'black', fontWeight: 'bold', fontSize: '18px' }}>{t('quantity')}</p>
                                                                </Col>
                                                                <Col md={9} xs={9} sm={9} style={{ textAlign: 'right' }}>
                                                                    <Button onClick={() => this.decreaseQuantity()} style={{ width: '20%', height: '70%', color: 'black', backgroundColor: '#efbe39', borderColor: 'white', borderWidth: 1, borderRadius: 0, marginLeft: 5, fontWeight: 'bold', paddingTop: '0px', fontFamily: 'MontserratMedium' }}><h3>-</h3></Button>
                                                                    <Button style={{ height: 40, width: 40, color: 'black', backgroundColor: 'white', borderColor: 'black', borderWidth: 1, borderRadius: 0, marginLeft: 5, cursor: "context-menu", fontWeight: 'bold', paddingTop: '0px', fontFamily: 'MontserratMedium' }}><h3>{this.props.cartModal.quantity}</h3></Button>
                                                                    <Button onClick={() => this.increaseQuantity()} style={{ width: '20%', height: '70%', color: 'black', backgroundColor: '#efbe39', borderColor: 'white', borderWidth: 1, marginLeft: 5, borderRadius: 0, fontWeight: 'bold', paddingTop: '0px', fontFamily: 'MontserratMedium' }}><h3>+</h3></Button>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col md={6}>
                                                            <Row>
                                                                <Col md={12} style={{ textAlign: 'center' }}>
                                                                    <Button style={{ width: '95%', color: '#56773a', backgroundColor: 'white', borderColor: '#efbe39', borderWidth: 3, marginTop: 15, borderRadius: "0px", cursor: "context-menu", marginBottom: "25px", fontWeight: "bold", fontSize: "20px", fontFamily: 'MontserratMedium' }}><span style={{ wordSpacing: '100px' }}>{t('total')} {this.props.cartModal.totalPrice + "€"}</span></Button>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={12}>
                                                                    <Row style={{ textAlign: 'center' }}>
                                                                        <Col>
                                                                            <Button className='button-order' onClick={() => this.closeModalCart()} style={{ fontWeight: 'bold', borderRadius: "0px", width: '70%', height: '70%', backgroundColor: '#56773a', borderColor: 'white', fontFamily: 'MontserratMedium' }}><p>{t('cancel')}</p></Button>
                                                                        </Col>
                                                                        <Col>
                                                                            <Button className='button-order' onClick={() => this.addToCart()} style={{ fontWeight: 'bold', width: '70%', height: '70%', backgroundColor: '#efbe39', borderColor: 'white', borderRadius: "0px", fontFamily: 'MontserratMedium', color: '#424142' }}><p>{t('proceed')}</p></Button>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>

                                                </Container>
                                            }
                                        </Translation>
                                    </Modal.Body> : null
                                }
                            </Modal>
                        }
                        <Row style={{ textAlign: 'left', marginTop: '10px', marginBottom: '10px' }}>
                            <Translation ns="restaurant">
                                {t =>
                                    <Col style={{ fontFamily: 'MontserratMedium', fontSize: '20px' }}>{t("typeDish")}</Col>
                                }
                            </Translation>
                        </Row>
                        <Row style={{ textAlign: "center" }}>
                            {
                                this.props.tags ?
                                    this.props.tags.tagsList.map(tag => {
                                        return (
                                            <Col style={{ width: '93px', paddingLeft:0, paddingRight:0 }} className="tagsMargin" sm={3} xs={6} md={"auto"} onClick={() => this.props.selectMenuTag(tag.id, this.props.restaurantMenus.tags, this.props.id)}>
                                                <div style={{ marginBottom: '10px' }}>
                                                    <img src={tag.image} style={{ width: '55px', height: '55px' }} />
                                                </div>
                                                <div style={{
                                                    textAlign:'center', lineHeight: '15px', color: tag.color, fontFamily: 'Montserrat-SemiBold', fontSize: '13px', textDecoration: this.props.restaurantMenus.tags.length === 0 && tag.id === Config.ALL_TAGS_ID ? 'underline' :
                                                        this.props.restaurantMenus.tags.includes(tag.id) ? 'underline' : 'none'
                                                }}>
                                                    {tag.name}
                                                </div>
                                            </Col>
                                        )
                                    }) : null
                            }
                        </Row>
                        <Row style={{ marginTop: '30px' }}>
                            <Col style={{ backgroundColor: '#F0F0F0', textAlign: 'center', color: '#557627', fontWeight: 'bold' }}>
                                <Translation ns="restaurant">
                                    {t => <h4 style={{ marginBottom: 0 }}>{t('menus.title').toUpperCase()}</h4>}
                                </Translation>
                            </Col>
                        </Row>
                        {this.props.restaurantMenus.menus.map(obj => {
                            return (
                                <Row style={{ marginTop: '10px' }}>
                                    <Col style={{ textAlign: 'left', color: 'rgb(241, 189, 54)' }}>
                                        <h5 style={{ fontFamily: 'Montserrat-Bold', fontWeight: 'bolder', marginBottom: 10 }} className={'menu-item-left'}>{obj.menuName}</h5>
                                        {obj.dishes.map(dish => {
                                            return (
                                                <Row style={{ color: 'rgb(112, 122, 72)', borderBottom: '1px solid #92C143', paddingTop: '10px', paddingBottom: '10px' }}>
                                                    <Col sm={4} className={'menu-item-left'}>
                                                        <Image className='dish-image' src={dish.dishPicture} onClick={() => this.openCartModal(dish.id)} fluid />
                                                    </Col>
                                                    <Col sm={8}>
                                                        <Row style={{ marginBottom: 10 }}>
                                                            <Col>
                                                                <p style={{ color: '#EFBE39', marginBottom: 0, fontFamily: 'MontserratMedium', fontSize: 16 }}>{dish.dishName}</p>
                                                            </Col>
                                                        </Row>
                                                        <Row style={{ marginBottom: 10 }}>
                                                            <Col>
                                                                <p className='description' style={{ color: '#34343D', fontFamily: 'MontserratRegular', fontSize: 14 }} xs={false}>{dish.dishDescription}</p>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={9} >
                                                                {
                                                                    dish.tags.map(tag => {
                                                                        return (<Image className="img-tags" src={tag.image} roundedCircle />
                                                                        )
                                                                    })
                                                                }
                                                            </Col>
                                                            <Col lg={3}>
                                                                <Row style={{ float: 'right' }}>
                                                                    <div style={{ width: 'auto', display: 'flex' }}>
                                                                        <p className='margin-price' style={{ color: '#EFBE39', marginBottom: 0, fontFamily: 'MontserratMedium', fontSize: 16 }}>{dish.dishPrice.toFixed(2) + ' €'}</p>
                                                                        <Image className='cart-image' src={shoppingCart} onClick={() => this.openCartModal(dish.id)} style={{ cursor: 'pointer' }} />
                                                                    </div>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>)
                                        }
                                        )}

                                    </Col>
                                </Row>
                            )
                        })
                        }
                    </Col>
                </div> :
                null
        );
    }
}

/**,
 * Maps state to props.
 * @param state the state.
 */
const mapStateToProps = (state) => {

    return {
        selectedRestaurant: state.selectedRestaurant,
        selectedDish: state.selectedDish,
        cartModal: state.cartModal,
        restaurantMenus: state.restaurantMenus,
        cart: state.cart,
        tags: state.tags
    };
};

export default connect(mapStateToProps, { selectMenuTag, fetchRestaurant, fetchDish, selectDish, fetchMenus, displayModal, displayModalCart, hideModalCart, setQuantity, setTotalPrice, setChecked, setCart, setCartIndex, fetchTags })(RestaurantMenu);
