import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Geocode from '../utility/GoogleGeocode';
import { withRouter } from "react-router";
import { withTranslation, Translation } from "react-i18next";
import { connect } from "react-redux";
import {
  Field,
  reduxForm,
  formValueSelector,
  SubmissionError,
} from "redux-form";
import VvFoodApi from "../api/VvFoodApi";
import AuthService from "../services/AuthService";
import Form from "react-bootstrap/Form";
import "./Cart.css";
import {
  setDiscountField,
  setCreditCardPayment,
  fetchUserData,
  submitOrder,
  setCardError,
  setDiscount
} from "../actions";
import { Link } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import icon from "../assets/image/icon.png";
import Alert from "react-bootstrap/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import Button from "react-bootstrap/Button";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import Utility from '../utility';
import Spinner from 'react-bootstrap/Spinner';
import checked from "../assets/image/checkbox-checked.svg";
import unchecked from "../assets/image/checkbox-unchecked.svg";

/**
 * Function for input rendering in the form.
 * @param {*} param0
 */
const renderField = ({ isWrong, maxLength, input, label, type }) => (
  <div>
    <Form.Control
      {...input}
      placeholder={label}
      type={type}
      style={{
        borderRadius: "0px",
        borderColor: isWrong ? "#EB0F2D" : "white",
        color: isWrong ? "#DF3D2F" : "#000000",
        boxShadow: "none",
        height: 40,
        fontFamily: "MontserratMedium",
        fontSize: 20,

      }}
      maxLength={maxLength}
    />
  </div>
);

/**
 * Function for checkbox rendering in the form.
 * @param {*} param0
 */
const InvoiceCheckbox = ({ input, meta: { touched, error } }) => (
  <Translation ns="checkout">
    {(t) => (
      <div className={'d-flex'}>
        <span>
          {
            input.checked ?
              <Image src={checked} style={{ width: '17px', marginTop: -5 }} onClick={() => { }} /> :
              <Image src={unchecked} style={{ width: '15px' }} onClick={() => { }} />
          }
        </span>
        <span>
          <Form.Check
            className='checkout-checkbox'
            type="checkbox"
            style={{ fontFamily: "MontserratLight" }}
            {...input}
          />
        </span>
        <h6 style={{ position: 'absolute', left: 25, top: 7, color: "#565751", fontFamily: "MontserratLight", fontSize: 14 }}>{t("company")}</h6>
      </div>
    )}
  </Translation>
);

/**
 * Function for checkbox rendering in the form.
 * @param {*} param0
 */
const PolicyCheckbox = ({ input, meta: { touched, error } }) => (
  <Translation ns="checkout">
    {(t) => (
      <div className={'d-flex'}>
        <span>
          {
            input.checked ?
              <Image src={checked} style={{ width: '17px', marginTop: -5 }} onClick={() => { }} /> :
              <Image src={unchecked} style={{ width: '15px' }} onClick={() => { }} />
          }
        </span>
        <span>
          <Form.Check
            className='checkout-checkbox'
            type="checkbox"
            style={{ fontFamily: "MontserratLight" }}
            {...input}
          />
        </span>
        <h6 style={{ position: 'absolute', left: 25, top: 7, color: "black", fontFamily: "MontserratRegular", fontSize: 14 }}>{t("policyAgreement")}</h6>
      </div>
    )}
  </Translation>
);

const TooltipButton = ({ text }) => (
  <OverlayTrigger
    placement="top"
    overlay={
      <Tooltip className={'custom-tooltip'} style={{ fontFamily: "MontserratRegular", fontWeight: 'bold' }}>{text}</Tooltip>
    }
  >
    <span className={'circle-icon'}>?</span>
  </OverlayTrigger>
);

/**
 * Checkout page.
 */
class Checkout extends React.Component {

  /**
   * checkout class constructor
   */
  constructor() {
    super();
    this.state = { failedFields: [], failedDiscount: false, loadingSpinner: false, address: new Map(), }
  }

  /**
   * Card fields style.
   */
  CARD_OPTIONS = {
    iconStyle: 'solid',
    style: {
      base: {
        fontFamily: 'Montserrat',
        paddingLeft: '5px',
        iconColor: '#c4f0ff',
        color: '#000',
        fontSize: '22px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': { color: '#fce883' },
        '::placeholder': { color: 'white' },
      },
      invalid: {
        iconColor: '#ffc7ee',
        color: 'red',
      },
    },
  };

  async componentDidMount() {
    if (AuthService.getCurrentUserId() != null)
      await this.props.fetchUserData(AuthService.getCurrentUserId());
    this.setState({ address: await Geocode(this.props.cart.deliveryAddress) })
  }

  canProceed() {
    if (this.props.cart.orderType !== 'pick-up') {
      if (this.props.cart.selDeliveryHour == '-') {
        return false
      }
    } else {
      if (this.props.cart.selHour == '-') {
        return false
      }
    }

    return true
  }
  /**
   * Validates the form values.
   * @param values the values.
   */
  validateForm = (values) => {
    var failedFieldKeys = [];

    if (typeof this.props.cart.cartContent === "undefined") {
      return failedFieldKeys;
    }
    if (this.props.cart.cartContent.length <= 0 || !this.canProceed()) {
      return failedFieldKeys;
    }

    if (!values.name || values.name.trim() === '') {
      failedFieldKeys.push('name');
    }
    if (!values.lastName || values.lastName.trim() === '') {
      failedFieldKeys.push('lastName');
    }
    if (
      !values.phoneNumber ||
      values.phoneNumber.trim() === '' ||
      !Utility.validatePhoneNumber(values.phoneNumber)
    ) {
      failedFieldKeys.push('phoneNumber');
    }
    if (!values.email || !Utility.validateEmail(values.email)) {
      failedFieldKeys.push('email');
    }
    if (this.props.cart.orderType === 'delivery') {
      if (!values.streetNumber || values.streetNumber.trim() === '') {
        failedFieldKeys.push('streetNumber');
      }
      if (
        !values.zip ||
        values.zip.trim() === '' ||
        !Utility.validatePostalCode(values.zip)
      ) {
        failedFieldKeys.push('zip');
      }
    }
    if (values.company) {
      if (!values.companyName || values.companyName.trim() === '') {
        failedFieldKeys.push('companyName');
      }
      if (!values.companyAddress || values.companyAddress.trim() === '') {
        failedFieldKeys.push('companyAddress');
      }
      if (!values.companyCode || values.companyCode.trim().length < 11 || !Utility.validateCompanyCode(values.companyCode)) {
        failedFieldKeys.push('companyCode');
      }
    }

    if (!values.privacyPolicyAgreed) {
      failedFieldKeys.push('agreePrivacy');
    }
    return failedFieldKeys;
  };

  /**
   * discount validation method
   */
  validateDiscount = async () => {
    var discountCode = document.getElementById('discountFieldUniqueId').value;
    var subTotal = this.calculateSum(this.props.cart.cartContent);

    await this.props.setDiscount(discountCode, subTotal, this.props.checkout.restaurantId);

    if (this.props.checkout.discountValue > 0) {
      this.setState({ failedDiscount: false })
    } else {
      this.setState({ failedDiscount: true })
    }
  };

  /**
   * Function for manage order submit.
   * @param {*} values
   */
  submit = async (values) => {

    await this.setState({ failedFields: this.validateForm(values) })
    if (this.state.failedFields.length > 0) {
      return;
    }

    if (typeof this.props.cart.cartContent === "undefined") {
      return;
    }
    if (this.props.cart.cartContent.length <= 0 || !this.canProceed()) {
      return;
    }

    this.setState({ loadingSpinner: true });
    var name = values.name
    var surname = values.lastName
    var phoneNumber = values.phoneNumber
    var email = values.email
    var streetNumber = values.streetNumber
    var street = this.state.address.get('street');
    var zip = values.zip
    var city = this.state.address.get('city');
    var companyName = values.companyName
    var companyAddress = values.companyAddress
    var companyCode = values.companyCode
    var restaurantId = this.props.checkout.restaurantId
    var orderType = this.props.cart.orderType === 'pick-up' ? 0 : 1
    var selectedHour = this.props.cart.orderType === 'pick-up' ? this.props.cart.selHour : this.props.cart.selDeliveryHour
    var selectedMinutes = this.props.cart.orderType === 'pick-up' ? this.props.cart.selMinute : this.props.cart.selDeliveryMinute
    var paymentType = this.props.checkout.isPaymentCreditCard ? "card" : "cash"
    var note = this.props.cart.note
    var deliveryPrice = orderType === 0 ? this.props.cart.deliveryPrice : 0
    var totalPrice = this.calculateSum(this.props.cart.cartContent)
    var userId = AuthService.getCurrentUserId()
    var orderedDishes = []
    this.props.cart.cartContent.forEach((obj) => {
      var addonsId = []
      var addonsMap = obj.modal.checkedItems
      for (let i = 0; i < addonsMap.length; i++) {
        addonsId.push(addonsMap[i])
      }
      orderedDishes.push({ dishId: obj.dish.dish.id, dishesQuantity: obj.modal.quantity, addonIds: addonsId })
    });

    var jsonToSend = {
      restaurantId: restaurantId,
      orderPrice: totalPrice,
      orderComment: note,
      orderDeliveryStatus: orderType,
      orderDeliverytime: selectedHour + ":" + selectedMinutes + ":00",
      orderDiscount: this.props.checkout.discountValue > 0 ? this.props.checkout.discountValue : null,
      orderDiscountCode: this.props.checkout.discountCode ? this.props.checkout.discountCode : null,
      orderNeedInvoice: this.props.invoice ? this.props.invoice : false,
      userId: userId,
      paymentMethod: paymentType,
      contacts: [{
        deliveryAddress: `${street} ${streetNumber}, ${zip}, ${city}`,
        contactName: name,
        contactSurname: surname,
        contactPhoneNumber: phoneNumber,
        contactEmail: email,
        contactCompanyName: companyName,
        contactCompanyCode: companyCode,
        contactCompanyAddress: companyAddress
      }],
      orderedDishes: orderedDishes
    };
    await this.props.submitOrder(jsonToSend)
    var error = await this.props.checkout.submitError
    const { stripe, elements } = this.props;
    if (error === true) {
      this.setState({ loadingSpinner: false });
    }
    if (paymentType === 'cash' && error === false) {
      this.setState({ loadingSpinner: false });
      this.props.history.push('/order-submit')
    } else if (paymentType === 'card' && error === false) {
      const result = await stripe.confirmCardPayment(this.props.checkout.clientSecret, {
        payment_method: {
          card: elements.getElement(CardNumberElement, CardExpiryElement, CardCvcElement),
        }
      });
      if (result.error) {
        this.setState({ loadingSpinner: false });
        this.props.setCardError(result.error.message)
      } else {
        this.setState({ loadingSpinner: false });
        if (result.paymentIntent.status === 'succeeded') {
          this.props.history.push('/order-submit')
        }
      }
      this.setState({ loadingSpinner: false });
    }
  }

  /**
   * method to calculate sum of total dish prices
   * @param {*} cart
   */
  calculateSum = (cart) => {
    var totalSum = 0;
    cart.forEach((v, k) => {
      totalSum += parseFloat(v.modal.totalPrice);
    });
    return totalSum;
  };

  /**
   * method to calculate sum of total dish prices
   * @param {*} cart
   */
  calculateSumWithDelivery = (cart) => {
    var totalSum = 0;
    cart.forEach((v, k) => {
      totalSum += parseFloat(v.modal.totalPrice);
    });
    if (this.props.cart.orderType == "delivery")
      totalSum += parseFloat(this.props.cart.deliveryPrice);
    totalSum -= this.props.checkout.discountValue;
    return totalSum;
  };

  /**
   * Rendering every addon of single dish.
   * @param content cart content containing single dish and cart data
   * @returns {JSX.Element[]}
   */
  renderDishAddons = (content) => {
    let addons = [];
    content.modal.checkedItems.forEach(checkedItem => {
      const {addonCategories} = content.dish;
      addonCategories.forEach(category => {
        const {addon} = category;
        let matchingAddon = addon.find(addon => addon.id === checkedItem);
        if (matchingAddon) {
          addons.push(matchingAddon);
        }
      });
    });

    return addons.map(({addonName, addonPrice, id}) => {
      return (
        <div key={id} className="checkout-addon">
          <div style={{ width: "60%", display: "inline-block", marginRight: "2%" }}>{`+ ${addonName}`}</div>
          <div style={{ width: "10%", display: "inline-block", marginRight: "2%" }}>{`x${content.modal.quantity}`}</div>
          <div style={{
            width: "25%",
            display: "inline-block",
            textAlign: "right",
          }}>{`${parseFloat(addonPrice).toFixed(2)} €`}</div>
        </div>
      );
    });
  }

  /**
   * method that renders a single dish
   * @param {*} content
   */
  renderDish = (content) => {
    return (
      <div
        style={{
          borderTop: "2 solid white",
          backgroundColor: "#557627",
          minHeight: 40,
          padding: "20px 25px",
          margin: "2px 0",
        }}
      >
        <div className={'checkout-dish'} >
          <div
            style={{ width: "60%", display: "inline-block", marginRight: "2%" }}
          >
            {content.dish.dish.dishName}
          </div>
          <div
            style={{ width: "10%", display: "inline-block", marginRight: "2%" }}
          >
            {"x" + content.modal.quantity}
          </div>
          <div
            style={{
              width: "25%",
              display: "inline-block",
              textAlign: "right",
            }}
          >
            {parseFloat(content.dish.dish.dishPrice).toFixed(2) + " €"}
          </div>
          {this.renderDishAddons(content)}
        </div>
      </div>
    );
  };


  /**
   * method that renders the contact details column
   */
  renderContactDetailsColumn = () => {
    const { error, handleSubmit, pristine, reset, submitting } = this.props;
    return (
      <Translation ns="checkout">
        {(t) => (
          <Container className={'pr-xl-4 pl-md-3 px-0'} style={{ marginBottom: "10px" }}>
            <Row>
              <Col md={12} style={{ textAlign: "center" }}>
                <div
                  style={{
                    fontSize: "30px",
                    color: "#565751",
                    fontFamily: "MontserratMedium",
                    textAlign: 'center',
                    marginTop: '-10px',
                    paddingBottom: 30,
                  }}
                >
                  {this.props.cart.orderType == "delivery"
                    ? t("deliveryDetails")
                    : t("pickupDetails")}
                </div>
              </Col>
            </Row>
            <form onSubmit={handleSubmit(this.submit)}>
              <Container className={'checkout-contact-form'}>
                <Row>
                  <Col md={12}
                    className={this.state.failedFields.includes("name") ? 'input-container invalid' : 'input-container'}>
                    <div className={'input-label'}>{t("name")}</div>
                    <Field name="name" type="text" component={renderField} />
                  </Col>
                  <Col md={12}
                    className={this.state.failedFields.includes("lastName") ? 'input-container invalid' : 'input-container'}>
                    <div className={'input-label'}>{t("surname")}</div>
                    <Field
                      name="lastName"
                      type="text"
                      component={renderField}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}
                    className={this.state.failedFields.includes("phoneNumber") ? 'input-container invalid' : 'input-container'}>
                    <div className={'d-flex input-label'}>
                      {t("phone")} <TooltipButton text={t("phonePopup")} />
                    </div>
                    <Field
                      name="phoneNumber"
                      type="text"
                      component={renderField}
                    />
                  </Col>
                  <Col md={12}
                    className={this.state.failedFields.includes("email") ? 'input-container invalid' : 'input-container'}>
                    <div className={'input-label'}>{t("email")}</div>
                    <Field name="email" type="text" component={renderField} />
                  </Col>
                </Row>
                {this.props.cart.orderType == "delivery" ? (
                  <Row>
                    <Col md={12} className={'input-container'}>
                      <div className={'input-label'}>{t("city")}</div>
                      <Field
                        name="city"
                        type="text"
                        component={renderField}
                        input={{ disabled: true, value: this.state.address ? this.state.address.get('city') : '' }}
                      />
                    </Col>
                    <Col md={12} className={'input-container'}>
                      <div className={'input-label'}>{t("street")}</div>
                      <Field
                        name="street"
                        type="text"
                        component={renderField}
                        input={{ disabled: true, value: this.state.address ? this.state.address.get('street') : '' }}
                      />
                    </Col>
                  </Row>
                ) : null}
                {this.props.cart.orderType == "delivery" ? (
                  <Row>
                    <Col md={6} className={'px-0 pr-md-2'}>
                      <div
                        className={this.state.failedFields.includes("streetNumber") ? 'input-container invalid' : 'input-container'}>
                        <div className={'input-label'}>{t("streetN")}</div>
                        <Field
                          name="streetNumber"
                          type="text"
                          component={renderField}
                        />
                      </div>
                    </Col>
                    <Col md={6} className={'px-0 pl-md-2'}>
                      <div
                        className={this.state.failedFields.includes("zip") ? 'input-container invalid' : 'input-container'}>
                        <div className={'d-flex input-label'}>
                          {t("zip")} <TooltipButton text={t("zipPopup")} />
                        </div>
                        <Field name="zip" type="text" component={renderField} maxLength="5" />
                      </div>
                    </Col>
                  </Row>
                ) : null}
                <Row style={{ paddingTop: 30, paddingBottom: 15 }}>
                  <Col md={12} className={'px-0'}>
                    <Field
                      name="company"
                      type="checkbox"
                      component={InvoiceCheckbox}
                    />
                  </Col>
                </Row>
              </Container>
              {this.props.invoice ? (
                <Container className="checkout-contact-form">
                  <Row>
                    <Col md={12}
                      className={this.state.failedFields.includes("companyName") ? 'input-container invalid' : 'input-container'}>
                      <div className={'input-label'}>{t("companyName")}</div>
                      <Field
                        name="companyName"
                        type="text"
                        component={renderField}
                      />
                    </Col>
                    <Col md={12}
                      className={this.state.failedFields.includes("companyCode") ? 'input-container invalid' : 'input-container'}>
                      <div className={'d-flex input-label'}>
                        {t("companyCode")}{" "}
                        <TooltipButton text={t("companyCodePopup")} />
                      </div>
                      <Field
                        name="companyCode"
                        type="text"
                        component={renderField}
                        maxLength="11"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}
                      className={this.state.failedFields.includes("companyAddress") ? 'input-container invalid' : 'input-container'}>
                      <div className={'input-label'}>{t("companyAddress")}</div>
                      <Field
                        name="companyAddress"
                        type="text"
                        component={renderField}

                      />
                    </Col>
                  </Row>
                </Container>
              ) : null}
              <Container>
                <Row style={{ paddingTop: 30 }}>
                  <Col md={12} className={'px-0'}>
                    <Field
                      name="privacyPolicyAgreed"
                      type="checkbox"
                      component={PolicyCheckbox}
                    />
                  </Col>
                </Row>
              </Container>
              {AuthService.getCurrentUserId() == null ?
                <Container>
                  <Row style={{ paddingTop: 15 }}>
                    <Col md={12} className={'px-0 d-flex'}>
                      <div style={{ fontSize: 12, fontFamily: 'MontserratRegular' }}>{t("alreadyAccount")}</div>
                      <Link to="/login" style={{ color: '#EB0E2F' }}>
                        <div style={{
                          fontSize: 12,
                          fontFamily: 'MontserratRegular',
                          color: '#EB0E2F',
                          paddingLeft: 5,
                        }}>{t("signIn")}</div>
                      </Link>
                    </Col>
                  </Row>
                </Container>
                : null}
              <div style={{ color: 'red', fontFamily: 'MontserratMedium', paddingTop: 20 }}>
                {this.state.failedFields.map(field => (<div>{t(field + ".invalid")}</div>))}
              </div>
            </form>
          </Container>
        )}
      </Translation>
    );
  };

  /**
   * method that renders the cart details column
   */
  renderCartDetailsColumn = () => {
    const { error, handleSubmit, pristine, reset, submitting } = this.props;
    return (
      <Translation ns="checkout">
        {(t) => (
          <Container className={'px-md-3 px-0'}>
            <form onSubmit={handleSubmit(this.submit)}>
              <Row>
                <Col md={12} style={{ textAlign: "center" }}>
                  <div className="hoursContainer">
                    <div className={'hoursLabel'} >
                      {this.props.cart.orderType == "delivery"
                        ? t("deliveryTime")
                        : t("pickupTime")}
                    </div>
                    <div
                      style={{
                        width: "50%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div className="pick-up-time-container" style={{ borderRight: "2px solid #ffffff" }}>
                        <span className="whiteBoldedNumber">
                          {this.props.cart.orderType == "delivery"
                            ? this.props.cart.selDeliveryHour
                            : this.props.cart.selHour}
                        </span>
                      </div>
                      <div className="pick-up-time-container">
                        <span className="whiteBoldedNumber">
                          {this.props.cart.orderType == "delivery"
                            ? this.props.cart.selDeliveryMinute
                            : this.props.cart.selMinute}
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row style={{ marginTop: 1, marginBottom: 1 }}>
                <Col md={12}>
                  {typeof this.props.cart.cartContent === "undefined"
                    ? null
                    : this.props.cart.cartContent.map((content) => {
                      return this.renderDish(content);
                    })}
                </Col>
              </Row>
              <Row style={{ marginBottom: 1 }}>
                <Col md={12}>
                  <div
                    style={{
                      padding: '25px 30px',
                      backgroundColor: "#92C143",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        borderColor: "#ffffff",
                        borderWidth: 1,
                        borderStyle: "solid",
                        backgroundColor: "#ffffff",
                      }}
                    >
                      <div>
                        <p
                          style={{
                            fontFamily: "Montserrat-SemiBold",
                            color: "#B2D37C",
                            width: "100%",
                            fontSize: 22,
                            paddingTop: 5,
                            paddingLeft: 5,
                            cursor: 'default'
                          }}
                        >
                          {t("note")}
                        </p>
                      </div>
                      <div>
                        <textarea
                          maxLength={300}
                          style={{
                            color: "#aee64f",
                            border: 0,
                            width: "100%",
                            outline: 0,
                            cursor: 'default'
                          }}
                          value={this.props.cart.note}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  {this.props.checkout.discountEnabled ? (
                    <div
                      style={{
                        padding: 30,
                        backgroundColor: "rgb(146, 193, 67)",
                        width: "100%",
                        marginTop: "2px",
                        paddingBottom: this.state.failedDiscount ? 10 : 30,
                      }}
                    >
                      <div
                        style={{
                          fontFamily: "MontserratMedium",
                          color: "#ffffff",
                          fontSize: 20,
                          marginBottom: "16px",
                          paddingLeft: 3,
                          paddingRight: 3,
                        }}
                      >
                        <span>{t("discountTitle")}</span>
                      </div>
                      <Row>
                        <Col md={7}>
                          <Field
                            name="discount"
                            type="text"
                            component={renderField}
                            input={{ id: 'discountFieldUniqueId' }}
                            isWrong={this.state.failedDiscount}
                          />
                        </Col>
                        <Col md={5} className={'pl-md-0 pt-2 pt-md-0'}>
                          <div className="discountButton" onClick={this.validateDiscount}>
                            {t("discountButton")}
                          </div>
                        </Col>
                      </Row>
                      {this.state.failedDiscount ?
                        <Row style={{ paddingTop: 10 }}>
                          <Col md={12}>
                            <div style={{ color: '#EB0F2D', fontFamily: 'MontserratMedium', fontSize: 22 }}>
                              {t('discount.invalid')}
                            </div>
                          </Col>
                        </Row> : null
                      }
                    </div>
                  ) : (
                      <div
                        style={{
                          padding: '30px 35px',
                          backgroundColor: "rgb(146, 193, 67)",
                          width: "100%",
                          marginTop: "2px",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "MontserratRegular",
                            color: "#003EA3",
                            fontSize: 20,
                          }}
                        >
                          <span
                            onClick={() => {
                              this.props.setDiscountField(true);
                            }}
                          >
                            {t("discountQuestion")}
                          </span>
                        </div>
                      </div>
                    )}
                </Col>
              </Row>
              <Row style={{ marginBottom: 1 }}>
                <Col md={12}>
                  <div
                    style={{
                      backgroundColor: "rgb(146, 193, 67)",
                      minHeight: 40,
                      padding: '0 30px 20px 30px',
                    }}
                  >
                    <div className="simpleTotalField">
                      {t("subtotal")}
                      <span style={{ display: "inline", float: "right" }}>
                        {this.calculateSum(this.props.cart.cartContent).toFixed(
                          2
                        ) + " €"}
                      </span>
                    </div>
                    {this.props.checkout.discountValue > 0 ?
                      <div className="simpleTotalField">
                        {t("discountval")}
                        <span style={{ display: "inline", float: "right" }}>
                          {"-" + this.props.checkout.discountValue.toFixed(
                            2
                          ) + " €"}
                        </span>
                      </div>
                      : null
                    }
                    {this.props.cart.orderType === "delivery" ? (
                      <div className="simpleTotalField deliveryTotalField">
                        {t("deliveryPrice")}
                        <span style={{ display: "inline", float: "right" }}>
                          {this.props.cart.deliveryPrice.toFixed(2) + " €"}
                        </span>
                      </div>
                    ) : null}
                    <div className="totalField">
                      {t("total")}
                      <span style={{ display: "inline", float: "right" }}>
                        {this.calculateSumWithDelivery(
                          this.props.cart.cartContent
                        ).toFixed(2) + " €"}
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
              {this.props.checkout.isPaymentCreditCard ?
                  (
                      this.props.selectedRestaurant && this.props.selectedRestaurant.stripeAccountId ? (
                          <Container
                              style={{
                                marginTop: this.props.cart.orderType !== "delivery" ? "0" : null,
                                marginBottom: this.props.cart.orderType !== "delivery" ? "15px" : null
                              }}
                              className="card-info-section"
                          >
                            <Row>
                              <Col md={12}>
                                <div className={'d-flex'}>
                                  {t("cardNumber")}{" "}
                                  <TooltipButton text={t("cardNumberPopup")} />
                                </div>
                                <div className={"custom-card-element"}>
                                  <CardNumberElement
                                      options={this.CARD_OPTIONS}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row >
                              <Col sm={12} md={6} lg={12} xl={6} style={{ paddingTop: '20px' }}>
                                <div className={'d-flex'}>
                                  {t("expirationYear")}{" "}
                                  <TooltipButton text={t("yearPopup")} />
                                </div>
                                <div className={"custom-card-element"}>
                                  <CardExpiryElement options={this.CARD_OPTIONS} />
                                </div>
                              </Col>
                              <Col sm={12} md={6} lg={12} xl={6} style={{ paddingTop: '20px' }}>
                                <div className={'d-flex'}>
                                  {t("cvc")} <TooltipButton text={t("cvcPopup")} />
                                </div>
                                <div className={"custom-card-element"}>
                                  <CardCvcElement options={this.CARD_OPTIONS} />
                                </div>
                              </Col>
                            </Row>
                            {this.props.checkout.cardError ?
                                <Row>
                                  <Col style={{ backgroundColor: 'red', margin: '15px' }}>{this.props.checkout.cardErrorMessage}</Col>
                                </Row> : null
                            }
                          </Container>
                      ) : (
                          <Row>
                            <Col>
                              <div style={{
                                padding: '30px 25px',
                                backgroundColor: '#fff',
                                border: '2px solid #557627',
                                marginTop: '15px',
                                display: 'flex',
                              }}>
                                <Translation ns="checkout">
                                  {(t) => (
                                      <p
                                          style={{
                                            color: '#557627',
                                            textAlign: 'center',
                                            margin: 0,
                                            fontFamily: "MontserratMedium"
                                          }}>
                                        {t('credit.card.payment.not.available')}
                                      </p>
                                  )}
                                </Translation>
                              </div>
                            </Col>
                          </Row>
                      )
                  ) : null
              }
              {this.props.cart.orderType == "delivery" ? (
                <Container className={'py-4'}>
                  <Row
                    className="justify-content-center pb-3"
                    style={{
                      textAlign: "center",
                      fontFamily: "Montserrat-SemiBold",
                      color: "#5B5B5B",
                      fontSize: 20,
                    }}
                  >
                    {t("paymentQuestion")}
                  </Row>
                  <Row
                    className="justify-content-center"
                    style={{
                      textAlign: "center",
                      fontFamily: "MontserratLight",
                      color: "#687850",
                      fontSize: "120%",
                    }}
                  >
                    <div className={'payment-type-container pr-lg-2'}>
                      <div
                        className={
                          this.props.checkout.isPaymentCreditCard
                            ? "paymentButton"
                            : "selectedPaymentButton"
                        }
                        onClick={() => this.props.setCreditCardPayment(false)}
                      >
                        {t("cash")}
                      </div>
                    </div>
                    <div className={'payment-type-container pl-lg-2'}>
                      <div
                        className={
                          this.props.checkout.isPaymentCreditCard
                            ? "selectedPaymentButton"
                            : "paymentButton"
                        }
                        onClick={() => this.props.setCreditCardPayment(true)}
                      >
                        {t("creditCard")}
                      </div>
                    </div>
                  </Row>
                </Container>
              ) : null}
              <Row>
                <Col md={12}>
                  <Link style={{ textDecoration: 'none' }}
                    to={
                      this.props.goToCheckoutModal.selectedRestaurant != null
                        ? "/restaurant/" +
                        this.props.goToCheckoutModal.selectedRestaurant
                        : "/#home"
                    }
                  >
                    <div className="orangeButton">{t("editButton")}</div>
                  </Link>
                </Col>
              </Row>
              <Row style={{ marginTop: 1 }}>
                <Col md={12}>
                  <Button disabled={this.props.checkout.isPaymentCreditCard && this.props.selectedRestaurant && !this.props.selectedRestaurant.stripeAccountId}variant="primary" type="submit" className="orangeButton" style={{ cursor: typeof this.props.cart.cartContent === "undefined" ? 'not-allowed' : this.props.cart.cartContent.length <= 0 || !this.canProceed() ? 'not-allowed' : 'pointer' }} onClick={() => { }} >
                    {t("submitButton")}
                  </Button>
                </Col>
              </Row>
            </form>
          </Container>
        )}
      </Translation>
    );
  };

  render() {
    return (
      <Container style={{ marginBottom: 100, marginTop: 20, position: "relative" }}>
        {this.state.loadingSpinner ?
          <div style={{ zIndex: "5", backgroundColor: "rgba(168, 168, 168, 0.3)", position: "fixed", top: "0", bottom: "0", left: "0", right: "0", textAlign: 'center' }}>
            <div style={{ justifyContent: 'center', alignItems: 'center', paddingTop: '30%' }}>
              <Spinner animation="border" style={{ color: "rgb(241, 197, 76)" }} />
            </div>
          </div> : null}
        <Col md={12} lg={11} className={'mx-auto'}>
          <Row style={{ paddingTop: 60, paddingBottom: 70 }}>
            <Col md={12}>
              <Translation ns="checkout">
                {(t) => (
                  <h1
                    style={{
                      textAlign: 'center',
                      color: '#565751',
                      fontFamily: 'MontserratMedium',
                      fontSize: 50,
                    }}>
                    {t('title')}
                  </h1>
                )}
              </Translation>
            </Col>
          </Row>
          <Row>
            <Col lg={6} className={'px-0 px-md-3'}>{this.renderContactDetailsColumn()}</Col>
            <Col lg={6} className={'px-0 px-md-3'}>{this.renderCartDetailsColumn()}</Col>
          </Row>
        </Col>
      </Container>
    );
  }
}

/**,
 * Maps state to props.
 * @param state the state.
 */
const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    checkout: state.checkout,
    goToCheckoutModal: state.goToCheckoutModal,
    selectedRestaurant: state.selectedRestaurant,
    initialValues: { ...state.userData, privacyPolicyAgreed: false, postalCode: '', houseNumber: '' },
  };
};

const selector = formValueSelector("checkout");
Checkout = connect((state) => {
  const invoice = selector(state, "company");
  return {
    invoice,
  };
})(Checkout);

// export default Checkout;
export default withRouter(connect(mapStateToProps, {
  setDiscountField,
  setCreditCardPayment,
  fetchUserData,
  submitOrder,
  setCardError,
  setDiscount,
})(
  reduxForm({
    form: "checkout",
    enableReinitialize: true,
  })(Checkout)
));
