import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import { connect } from "react-redux";
import { setGoToCheckoutModalVisibility, setGoToCheckout } from "../actions";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Translation } from "react-i18next";
import { Link } from "react-router-dom";
import {withRouter} from 'react-router';

/**
 * GoToCheckoutModal component.
 */
class GoToCheckoutModal extends React.Component {
  /**
   * Constructor
   * @param {*} props to set.
   */
  constructor() {
    super();
  }

  render() {
    return (
      <Modal
        show={this.props.goToCheckoutModal.isVisible}
        dialogClassName={"checkout-modal"}
        size="lg"
      >
        <FontAwesomeIcon
          onClick={() => this.props.setGoToCheckoutModalVisibility(false)}
          icon={faTimes}
          size="lg"
          color="#38383A"
          style={{ position: "absolute", right: 10, top: 10, zIndex: 1, cursor: 'pointer'  }}
        />
        <Modal.Body style={{ alignContent: "center", paddingTop: "50px"}}>
          <Translation ns="goToCheckout">
            {(t) => (
              <Container>
                <Col md={12}>
                  <Row
                    className="justify-content-center"
                    style={{
                      textAlign: "center",
                      fontFamily: "Montserrat-Bold",
                      color: "#000000",
                      fontSize: 24,
                      paddingTop: 30,
                      paddingBottom: 15,
                    }}
                  >
                    {t("title")}
                  </Row>
                  <Row className="justify-content-center not-reg-yet-subtitle">
                    {t("subtitle")}
                  </Row>
                  <Row
                    className="justify-content-center"
                    style={{
                      textAlign: "center",
                      fontFamily: "MontserratMedium",
                      paddingTop: 20,
                    }}
                  >
                      <div
                        className="modalLoginButton"
                        onClick={() => {this.props.setGoToCheckout(true); this.props.history.push('/login')}}
                      >
                        {t("button.login").toUpperCase()}
                      </div>
                      <div
                        className="modalRegisterButton"
                        onClick={() => {this.props.setGoToCheckout(true); this.props.history.push('/register')}}
                      >
                        {t("button.register").toUpperCase()}
                      </div>
                    <div className="modalGuestButton">
                      <Link to={"/checkout"} onClick={() => this.props.setGoToCheckoutModalVisibility(false)}>
                        {t("button.guest")}
                      </Link>
                    </div>
                  </Row>
                </Col>
              </Container>
            )}
          </Translation>
        </Modal.Body>
      </Modal>
    );
  }
}

/**,
 * Maps state to props.
 * @param state the state.
 */
const mapStateToProps = (state) => {
  return {
    goToCheckoutModal: state.goToCheckoutModal,
  };
};

export default withRouter(connect(mapStateToProps, {
  setGoToCheckoutModalVisibility,
  setGoToCheckout,
})(GoToCheckoutModal));
