import React from 'react';
import { Container, Row, Col, Form, Button, Image, CardDeck, Card } from "react-bootstrap";
import FirstImage from '../assets/image/first-home-image.png'
import firstImageIt from '../assets/image/Home-head-img-ita-2.png'
import firstImageEn from '../assets/image/Home-head-img-eng-2.png'
import Warning from '../assets/image/warning.png'
import OneImage from '../assets/image/1.png'
import TwoImage from '../assets/image/2.png'
import ThreeImage from '../assets/image/3.png'
import VWhite from '../assets/image/vv-white.png'
import VTLogo from '../assets/image/vtlogo.png'
import { connect } from 'react-redux'
import { setAddress, setDeliveryAddress, setName, fetchRestaurantsByAddress, displayNoRestaurantsNearby, displayRestaurantsFound, fetchNearestRestaurant, fetchTags, selectTag, selectFilter } from '../actions'
import RestaurantCard from './RestaurantCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWindowClose } from '@fortawesome/free-regular-svg-icons'
import './Home.css'
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import Config from '../utility/Config';
import { withTranslation, Translation } from 'react-i18next';
import SearchLocationInput from './SearchLocationInput'
import Spinner from 'react-bootstrap/Spinner';



/**
 * Home component
 */
class Home extends React.Component {

    constructor() {
        super();
        this.state = { address: '', displaySearchByName: true, loadingSpinner: false }
    }

    componentDidMount = async () => {
        await this.props.fetchTags();
    }

    /**
     * Function to select a filter.
     * @param {*} field filter
     */
    async filter(field) {
        if (field === "opened-now")
            await this.props.selectFilter(this.props.filters.freeDelivery, this.props.filters.homeDelivery, this.props.filters.localPickup, !this.props.filters.openedNow);
        else if (field === "free-delivery")
            await this.props.selectFilter(!this.props.filters.freeDelivery, this.props.filters.homeDelivery, this.props.filters.localPickup, this.props.filters.openedNow);
        else if (field === "home-delivery")
            await this.props.selectFilter(this.props.filters.freeDelivery, !this.props.filters.homeDelivery, this.props.filters.localPickup, this.props.filters.openedNow);
        else if (field === "local-pickup")
            await this.props.selectFilter(this.props.filters.freeDelivery, this.props.filters.homeDelivery, !this.props.filters.localPickup, this.props.filters.openedNow);

        this.setState({ loadingSpinner: true });
        await this.props.fetchRestaurantsByAddress(this.props.restaurants.address, this.props.restaurants.name, this.props.selectedTags, this.props.filters.freeDelivery, this.props.filters.homeDelivery, this.props.filters.localPickup, this.props.filters.openedNow);
        this.setState({ loadingSpinner: false });
    }

    /**
     * method to display nearby restaurants list, or to display no restaurants message
     * if there are no restaurants in user's selected area
     */
    displaySearchedRestaurantsList = async () => {
        if (this.props.restaurants.address == '') {
            return;
        } else {
            this.setState({ loadingSpinner: true });
            await this.props.fetchRestaurantsByAddress(this.props.restaurants.address, this.props.restaurants.name, this.props.tags.selectedTags, this.props.filters.freeDelivery, this.props.filters.homeDelivery, this.props.filters.localPickup, this.props.filters.openedNow);
            if (this.props.restaurants.restaurantsByAddress.length === 0 && this.props.restaurants.name === null && this.props.tags.selectedTags.length === 0) {
                await this.props.fetchNearestRestaurant(this.props.restaurants.address);
                this.props.displayNoRestaurantsNearby();
            } else {
                this.setState({ displaySearchByName: this.props.restaurants.restaurantsByAddress.length !== 0 })
                this.props.displayRestaurantsFound();
            }
            this.setState({ loadingSpinner: false });
        }
    }

    /**
     * Method displays search by restaurant name bar.
     */
    displaySearchByRestaurantName = () => {
        this.setState({
            displaySearchByName: true,
        })
    }

    /**
    * create and align cards filtered
    * @return list of cards
    */
    restaurantCardAlignedList = () => {
        let availableRestaurants = this.props.restaurants.restaurantsByAddress;
        let colors = ['(240 ,179 ,54 , 0.8)', '(91, 127, 27 , 0.8)'];
        let textColors = ['#414042', '#FFFFFF'];

        let resList = [];
        let colorIndex = 0;
        let textColorIndex = 0;
        for (let i = 0; i < availableRestaurants.length; i += 2) {
            let children = [];
            for (let j = i; j < i + 2 && j < availableRestaurants.length; j++) {
                children.push(<Col xl={6} lg={12}><RestaurantCard colorInfo={colors[colorIndex]} textColorInfo={textColors[textColorIndex]} resource={availableRestaurants[j]} /></Col>);
            }
            resList.push(<Row>{children}</Row>)
            colorIndex = (colorIndex + 1) % colors.length;
            textColorIndex = (textColorIndex + 1) % textColors.length;
        }
        return resList;
    };

    /**
     * checks if a tag is selected
     * @param {*} tagId
     */
    checkTagSelected = (tagId) => {
        return (this.props.tags.selectedTags.includes(tagId)) || (this.props.tags.selectedTags.length == 0 && tagId == Config.ALL_TAGS_ID)
    };

    tagsSelectors = () => {
        let tags = this.props.tags.tagsList;
        let resList = [];
        let children = [];
        for (let j = 0; j < tags.length; j++) {
            children.push(
                <Col xs={4} style={{ paddingBottom: 15 }}>
                    <div style={{ cursor: 'pointer', borderBottom: this.checkTagSelected(tags[j].id) ? '1px solid red' : '0px' }} onClick={async () => { await this.props.selectTag(tags[j].id, this.props.tags.selectedTags); this.displaySearchedRestaurantsList() }}>
                        <div style={{ textAlign: 'center' }}><Image style={{ maxHeight: 65 }} src={tags[j].image} /></div>
                        <div style={{ textAlign: 'center', width: '100%', color: tags[j].color, fontWeight: 'bold', marginTop: 8, fontFamily: 'MontserratMedium', lineHeight: '18px', fontSize: '15px' }}>{tags[j].name}</div>
                    </div>
                </Col>
            )
        }
        resList.push(<Row id="tagList" style={{ paddingLeft: '1rem' }}>{children}</Row>)
        return resList;
    };

    render() {
        return (
            <div style={{fontFamily: 'MontserratMedium'}}>
                <Container fluid id='home' className='home-container'>
                    {this.state.loadingSpinner ?
                        <div style={{ zIndex: "5", backgroundColor: "rgba(168, 168, 168, 0.3)", position: "fixed", top: "0", bottom: "0", left: "0", right: "0", textAlign: 'center' }}>
                            <div style={{ justifyContent: 'center', alignItems: 'center', paddingTop: '30%' }}>
                                <Spinner animation="border" style={{ color: "rgb(241, 197, 76)" }} />
                            </div>
                        </div> : null}
                    <Row>
                        <Image src={localStorage.getItem("websiteLang") == 'it' ? firstImageIt : firstImageEn} style={{ width: '100%' }} />
                    </Row>
                    <div id='order-now' className="scroll-margin">
                        {((this.props.restaurants.noRestaurantsNearbyIsDisplayed === false) &&
                            (this.props.restaurants.restaurantsFoundIsDisplayed === false)) &&
                            <Row className="justify-content-center search-bar" style={{marginBottom: 25}}>
                                <Col md={10} style={{ display: 'flex', marginTop: '30px', marginBottom: '30px' }}>
                                    <Translation ns="common">
                                        {t =>
                                            <SearchLocationInput searchFunction={this.displaySearchedRestaurantsList} setStateCallback={(address) => { if (typeof address !== 'undefined') { this.props.setAddress(address); this.props.setDeliveryAddress(address) } }} defaultValue={''} value={this.props.restaurants.address} inputStyle={{ outline: 'none', borderRadius: '0px', fontSize: '25px', width: '100%', border: 0, padding: 5, paddingLeft: 15, paddingRight: 15 }} containerStyle={{ width: '100%' }} placeholder={t('search.placeholder')} />
                                        }
                                    </Translation>
                                    <Translation ns="common">
                                        {t => <Button style={{ marginLeft: 15, paddingLeft: 30, paddingRight: 30, borderRadius: '0px', backgroundColor: '#92c143', color: 'white', fontWeight: 'bold', border: 'none' }} onClick={() => this.displaySearchedRestaurantsList()}>
                                            {t('search.button')}
                                        </Button>
                                        }
                                    </Translation>
                                </Col>
                            </Row>
                        }
                        {this.props.restaurants.noRestaurantsNearbyIsDisplayed &&
                            <Row style={{ marginTop: 50, marginBottom: 30 }} >
                                <Col md={12}>
                                    <Row className="justify-content-center" >
                                        <Col md={8} style={{ textAlign: "center", backgroundColor: '#d83237', padding: '10px 40px' }} id='no-nearby-restaurants'>
                                            <Translation ns="home">
                                                {t => <p className="no-rest-found-msg">{t('no-rest-nearby-1.msg')}</p>}
                                            </Translation>
                                            <Translation ns="home">
                                                {t => <p className="no-rest-found-msg">{t('no-rest-nearby-2.msg')}</p>}
                                            </Translation>
                                            <Translation ns="home">
                                                {t => <p className="no-rest-found-msg">{t('no-rest-nearby-3.msg')}</p>}
                                            </Translation>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-center" style={{ margin: '65px 0 55px' }} >
                                        <Col md={3}>
                                            <RestaurantCard colorInfo={'240, 179, 54, 0.8'} textColorInfo={'#414042'} resource={this.props.restaurants.nearestRestaurant} />
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-center" >
                                        <Col md={8} style={{ textAlign: "center", padding: '0 40px' }} >
                                            <Translation ns="home">
                                                {t => <p style={{ fontSize: 27, fontFamily: 'MontserratMedium' }}>{t('choose-other-rest.msg')} </p>}
                                            </Translation>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        }
                    </div>
                </Container>
                {this.props.restaurants.restaurantsFoundIsDisplayed &&
                    <Container className='home-container'>
                        <Row className="justify-content-center" style={{ marginTop: 50, marginBottom: 30 }}>
                            <Col md={12}>
                                <Row>
                                    <Col md={12} style={{ textAlign: "center", border: '2px solid rgb(91, 127, 27)', padding: '10px 40px' }}>
                                        <Translation ns="home">
                                            {t =>
                                                <p style={{ color: '#666765', fontSize: 30, fontFamily: 'MontserratMedium', fontWeight: 'bold', marginTop: '1rem' }}>
                                                    {t('rest-found-in.msg')} {this.props.restaurants.address}
                                                </p>
                                            }
                                        </Translation>
                                    </Col>
                                </Row>
                                <Row>
                                    {!this.state.displaySearchByName &&
                                        <Col lg={9} sm={12} style={{ textAlign: 'left', border: '2px solid rgb(216, 50, 55)', padding: '13px 18px', margin: '50px 0 35px 0' }}
                                            onClick={this.displaySearchByRestaurantName}>
                                            <Translation ns="home">
                                                {t =>
                                                    <p style={{ color: 'rgb(216, 50, 55)', fontSize: 25, fontFamily: 'MontserratMedium', boxShadow: 'none', border: 'none', marginTop: '1rem' }}>
                                                        {t('no.restaurants.by.name')}
                                                    </p>
                                                }
                                            </Translation>
                                        </Col>
                                    }
                                    {this.state.displaySearchByName &&
                                        <Col lg={9} sm={12} style={{ textAlign: 'left', border: '2px solid rgb(91, 127, 27)', padding: '22px 8px', margin: '50px 0 35px 0' }}>
                                            <Translation ns="home">
                                                {t =>
                                                    <Form.Control
                                                        id={'search-restaurant-by-name'}
                                                        onBlur={e => this.props.setName(e.target.value)}
                                                        style={{
                                                            color: 'rgb(91, 127, 27)',
                                                            fontFamily: 'MontserratMedium',
                                                            fontSize: 25,
                                                            boxShadow: 'none',
                                                            border: 'none'
                                                        }}
                                                        type="text"
                                                        placeholder={t('search-rest-by-name.msg')} />
                                                }
                                            </Translation>
                                        </Col>
                                    }
                                    <Col lg={3} sm={12} className="search-by-name-row">
                                        <Translation ns="common">
                                            {t =>
                                                <Button onClick={() => this.displaySearchedRestaurantsList()}
                                                    className="search-by-name-btn">
                                                    {t('search.name')}
                                                </Button>
                                            }
                                        </Translation>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={{ span: 4, order: 2 }} sm={12} >
                                        <Row>
                                            <Translation ns="home">
                                                {t =>
                                                    <p style={{ width: '100%', color: 'black', backgroundColor: 'lightgrey', fontSize: 25, fontFamily: 'MontserratMedium', fontWeight: 'bold', marginTop: '0.4rem' }}>
                                                        <span style={{ paddingLeft: '1rem' }}>{t('tags.title')}</span>
                                                    </p>
                                                }
                                            </Translation>
                                        </Row>
                                        {this.tagsSelectors()}
                                        <Row>
                                            <p style={{ width: '100%', color: 'black', backgroundColor: 'lightgrey', fontSize: 25, fontFamily: 'MontserratMedium', fontWeight: 'bold', marginTop: '0.4rem' }}>
                                                <Translation ns="home">
                                                    {t =>
                                                        <span style={{ paddingLeft: '1rem' }}>{t('filter.by')}</span>
                                                    }
                                                </Translation>
                                            </p>
                                            <Col md={12}>
                                                <Translation ns="home">
                                                    {t =>
                                                        <Form.Check id="formBasicCheckbox" style={{ fontSize: 20, fontFamily: 'MontserratMedium' }} type="checkbox" label={t('opened.now') + "(" + this.props.restaurants.openedNow + ")"} onChange={() => this.filter("opened-now")} />
                                                    }
                                                </Translation>
                                            </Col>
                                            <Col md={12}>
                                                <Translation ns="home">
                                                    {t =>
                                                        <Form.Check id="formBasicCheckboxTwo" style={{ fontSize: 20, fontFamily: 'MontserratMedium' }} type="checkbox" label={t('free.delivery') + "(" + this.props.restaurants.freeDelivery + ")"} onChange={() => this.filter("free-delivery")} />
                                                    }
                                                </Translation>
                                            </Col>
                                            <Col md={12}>
                                                <Translation ns="home">
                                                    {t =>
                                                        <Form.Check id="formBasicCheckboxThree" style={{ fontSize: 20, fontFamily: 'MontserratMedium' }} type="checkbox" label={t('home.delivery') + "(" + this.props.restaurants.homeDelivery + ")"} onChange={() => this.filter("home-delivery")} />
                                                    }
                                                </Translation>
                                            </Col>
                                            <Col md={12}>
                                                <Translation ns="home">
                                                    {t =>
                                                        <Form.Check id="formBasicCheckboxFour" style={{ fontSize: 20, fontFamily: 'MontserratMedium' }} type="checkbox" label={t('local.pickup') + "(" + this.props.restaurants.localPickup + ")"} onChange={() => this.filter("local-pickup")} />
                                                    }
                                                </Translation>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={{ span: 8, order: 1 }} sm={12} className="restaurantListCol pl-lg-0">
                                        {this.restaurantCardAlignedList()}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                }
                {!this.props.restaurants.restaurantsFoundIsDisplayed && !this.props.restaurants.noRestaurantsNearbyIsDisplayed ? (
                    <div>
                        <Container className='home-container'>
                            <Row className="justify-content-center" style={{ marginBottom: 30 }}>
                                <Col md={12} className="scroll-margin" style={{ textAlign: "center", backgroundColor: '#F0EFEF' }} id='what-do'>
                                    <Translation ns="common">
                                        {t =>
                                            <h3 style={{ color: 'rgb(237, 189, 57)', fontWeight: 'bold', marginBottom: 0}}>{t("whatToDo").toUpperCase()}</h3>
                                        }
                                    </Translation>
                                </Col>
                            </Row>
                            <Row className="justify-content-center" style={{ marginBottom: 30 }}>
                                <Col md={12} style={{ textAlign: "center" }}>
                                    <Translation ns="common">
                                        {t =>
                                            <Row>
                                                <Col xs={12} sm={4}>
                                                    <Image src={OneImage} fluid />
                                                    <h3 style={{ color: '#92c143' }}><span style={{fontWeight: 'bold', color: 'rgb(54, 86, 43)'}}>{t("checkAreaTitle1")}</span> <br/> {t("checkAreaTitle2")}</h3>
                                                    <h5 className='small-text-box' >{t("checkAreaText")}</h5>
                                                </Col>
                                                <Col xs={12} sm={4}>
                                                    <Image src={TwoImage} fluid />
                                                    <h3 style={{ color: '#92c143' }}><span style={{fontWeight: 'bold', color: 'rgb(54, 86, 43)'}}>{t("orderDishTitle1")}</span> <br/> {t("orderDishTitle2")}</h3>
                                                    <h5 className='small-text-box' >{t("orderDishText")}</h5>
                                                </Col>
                                                <Col xs={12} sm={4}>
                                                    <Image src={ThreeImage} fluid />
                                                    <h3 style={{ color: '#92c143' }}><span style={{fontWeight: 'bold', color: 'rgb(54, 86, 43)'}}>{t("readyEatTitle1")}</span> <br/> {t("readyEatTitle2")}</h3>
                                                    <h5 className='small-text-box'>{t("readyEatText")}</h5>
                                                </Col>
                                            </Row>
                                        }
                                    </Translation>
                                </Col>
                            </Row>
                            <Row className="justify-content-center scroll-margin" style={{ marginTop: 30, marginBottom: 30 }} id='company'>
                                <Col md={12} style={{ textAlign: "center", backgroundColor: '#F0EFEF' }}>
                                    <Translation ns="common">
                                        {t =>
                                            <h3 style={{ color: 'rgb(79, 109, 55)', fontWeight: 'bold', marginBottom: 0 }}>{t("companies").toUpperCase()}</h3>
                                        }
                                    </Translation>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col md={12}>
                                    <Translation ns="common">
                                        {t =>
                                            <Row >
                                                <Col xs={12} sm={6}>
                                                    <h3 style={{ color: 'rgb(146, 193, 67)' }}>{t("agreementsTitle1")}</h3>
                                                    <h3 style={{ color: '#4f6d37', marginLeft: 140 }}>{t("agreementsTitle2")}<span style={{fontWeight: 'bold'}}>{t("agreementsTitle3")}</span></h3>
                                                </Col>
                                                <Col xs={12} sm={6}>
                                                    <h3>{t("agreementsText")}</h3>
                                                </Col>
                                            </Row>
                                        }
                                    </Translation>
                                    <Row className="justify-content-center" style={{ marginTop: 20 }}>
                                        <Col style={{ textAlign: 'center' }}>
                                            <a href="mailto:info@vvfood.it">
                                                <Translation ns="common">
                                                    {t =>
                                                        <Button style={{ paddingLeft: 50, paddingRight: 50, borderRadius: 0, backgroundColor: '#F1C54C', color: 'black', border: 'none' }}><h3 style={{marginBottom: 0}}>{t("writeUs")}</h3></Button>
                                                    }
                                                </Translation>
                                            </a>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                        <Container fluid className="philosophy scroll-margin" id='philosophy'>
                            <Translation ns="common">
                                {t =>
                                    <Col md={12}>
                                        <h1 style={{ textAlign: 'center', color: 'white', marginBottom: 50, fontWeight: 'bold' }}>{t("philosophy").toUpperCase()}</h1>
                                        <Container className='home-container'>
                                            <CardDeck>
                                                <Card className="card-color">
                                                    <Card.Body style={{ textAlign: 'center' }}>
                                                        <Card.Title style={{ color: 'rgb(54, 86, 43)' }}><h3 style={{fontWeight: 'bold'}}>{t("cardOneTitle")}</h3></Card.Title>
                                                        <Card.Text style={{ color: 'white' }}>
                                                            {t("cardOneText")}
                                                        </Card.Text>
                                                    </Card.Body>
                                                </Card>
                                                <Card className="card-color">
                                                    <Card.Body style={{ textAlign: 'center' }}>
                                                        <Card.Title style={{ color: 'rgb(54, 86, 43)' }}><h3 style={{fontWeight: 'bold'}}>{t("cardTwoTitle")}</h3></Card.Title>
                                                        <Card.Text style={{ color: 'white' }}>
                                                            {t("cardTwoText")}
                                                        </Card.Text>
                                                    </Card.Body>
                                                </Card>
                                                <Card className="card-color">
                                                    <Card.Body style={{ textAlign: 'center' }}>
                                                        <Card.Title style={{ color: 'rgb(54, 86, 43)' }}><h3 style={{fontWeight: 'bold'}}>{t("cardThreeTitle")}</h3></Card.Title>
                                                        <Card.Text style={{ color: 'white' }}>
                                                            {t("cardThreeText")}
                                                        </Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            </CardDeck>
                                        </Container>
                                    </Col>
                                }
                            </Translation>
                        </Container>
                    </div>
                ) : null}
            </div>
        );
    }
}

/**,
 * Maps state to props.
 * @param state the state.
 * @returns {{restaurants: *}}
 */
const mapStateToProps = (state) => {
    return {
        restaurants: state.restaurants,
        tags: state.tags,
        filters: state.filters
    };
};

// export default Home;
export default connect(
    mapStateToProps,
    {
        setAddress,
        setDeliveryAddress,
        setName,
        fetchRestaurantsByAddress,
        displayNoRestaurantsNearby,
        displayRestaurantsFound,
        fetchNearestRestaurant,
        fetchTags,
        selectTag,
        selectFilter
    })
    (withTranslation()(Home));
