import React from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'
import Cart from '../assets/image/cart.png'
import It from '../assets/image/it.png'
import En from '../assets/image/en.png'
import Logo from '../assets/image/logo.png'
import MenuIcon from '../assets/image/menu-icon.png'
import { withRouter } from 'react-router';
import './Home.css'
import { Translation } from "react-i18next";
import AuthService from './../services/AuthService'
import UserActionsPopup from './UserActionsPopup';
import { connect } from 'react-redux'
import { Link } from "react-router-dom";

class Header extends React.Component {

    componentDidMount() {
        document.addEventListener('scroll', function(e) {
            var header = document.getElementById('header')
            if(window.scrollY == 0) {
                header.style.boxShadow = 'none';
            } else {
                header.style.boxShadow = '0 10px 20px rgba(0,0,0,0.3)';
            }
        });
    }
    /**
    * Function for hide and show the drop down menu.
    */
    showMenu() {
        var x = document.getElementById('drop-down-menu');
        if (window.getComputedStyle(x).display === "none") {
            document.getElementById('drop-down-menu').style.cssText = 'display:block;';
        } else {
            document.getElementById('drop-down-menu').style.cssText = 'display:none;';
        }
    }

    /**
     * Function for scroll to a specific element.
     * @param {*} el element to reach with the scroll.
     */
    async scrollToElement(el) {
        if (el !== '') {
            window.location.href = "/#" + el;
        } else {
            window.location.href = "/";
        }
    }

    /**
     * Switches the language and keeps the preference in local storage,
     * and reloads the page in order to request localized data again.
     * @param lang the language key to change.
     */
    switchLang(lang) {
        localStorage.setItem("websiteLang", lang);
        window.location.reload();
    }

    render() {
        return (
            <Container fluid id='header' className="header-white-bg sticky">
                <Row className="header-row">
                    <Col md={4} xs={3}>
                        <Image src={Logo} style={{cursor: 'pointer'}} onClick={() => window.location.href='/'} className='logo'></Image>
                    </Col>
                    <Col md={8} xs={9}>
                        <Row>
                            <Col md={12} xs={12}>
                                <div>
                                    <div className='menu top'>
                                        {AuthService.getCurrentUserId() == null ? <span>
                                            <Translation ns="header">
                                                {t =>
                                                    <div className='itemLoginRegister' onClick={() => window.location.href = '/register'} style={{ fontFamily: 'MontserratLight', fontWeight: 'bold' }}>
                                                        {t('register')}
                                                    </div>
                                                }
                                            </Translation>
                                            <Translation ns="header">
                                                {t =>
                                                    <div className='itemLoginRegister' onClick={() => window.location.href = '/login'} style={{ fontFamily: 'MontserratLight', fontWeight: 'bold', paddingLeft: '25px' }}>
                                                        {t('login')}
                                                    </div>
                                                }
                                            </Translation>
                                        </span> : <UserActionsPopup />}
                                        {AuthService.getCurrentUserId() == null ?
                                            this.props.cart.cartContent.length > 0 ?
                                                <span style={{ paddingTop: '5px', width: '30px', height: '30px', borderRadius: '50%', backgroundColor: '#e98b46', color: 'white', display: 'inline-block', textAlign: 'center', fontFamily: 'MontserratLight' }}>
                                                    {this.props.cart.cartContent.length}
                                                </span> : null : null}
                                        {AuthService.getCurrentUserId() == null ?
                                            <Link to={
                                                this.props.cart.cartContent.length > 0 ?
                                                    "/restaurant/" + this.props.restaurant :
                                                    '/'
                                            }>
                                                <div className='item'>
                                                    <Image className="xs-menu-icon" onClick={() => this.props.cart.cartContent.length === 0 ? this.scrollToElement('order-now') : null} src={Cart} />
                                                </div>
                                            </Link> : null}
                                        <div className='item' onClick={() => this.switchLang('it')} style={{ cursor: 'pointer' }}>
                                            <Image src={It} className="xs-menu-icon" />
                                        </div>
                                        <div className='item' onClick={() => this.switchLang('en')} style={{ cursor: 'pointer' }}>
                                            <Image src={En} className="xs-menu-icon"/>
                                        </div>
                                        <div className='menuIcon' style={{ padding: '10px' }}>
                                            <Image src={MenuIcon} onClick={this.showMenu} fluid />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div className='menu-bottom'>
                            <Row className='menu-bottom' style={{ justifyContent: 'right', textAlign: 'right', marginTop: '20px' }}>
                                <Col md={12} lg={12} style={{ backgroundColor: '#92c143', paddingTop: '7px', paddingBottom: '7px', textAlign: 'center', borderRadius: '3px' }}>
                                    <UserActionsPopup isModal={true}/>
                                    <Translation ns="header">
                                        {t => <div className={localStorage.getItem("websiteLang") == 'en' ? 'menu-item-en' : 'menu-item'} onClick={() => this.scrollToElement('')} style={{ textDecoration: 'underline', color: '#40453e' }}>{t('home')}</div>}
                                    </Translation>
                                    <Translation ns="header">
                                        {t => <div className={localStorage.getItem("websiteLang") == 'en' ? 'menu-item-en' : 'menu-item'} onClick={() => this.scrollToElement('order-now')}>{t('orderNow')}</div>}
                                    </Translation>
                                    <Translation ns="header">
                                        {t => <div className={localStorage.getItem("websiteLang") == 'en' ? 'menu-item-en' : 'menu-item'} onClick={() => this.scrollToElement('what-do')}>{t('instructions')}</div>}
                                    </Translation>
                                    <Translation ns="header">
                                        {t => <div className={localStorage.getItem("websiteLang") == 'en' ? 'menu-item-en' : 'menu-item'} onClick={() => this.scrollToElement('company')}>{t('company')}</div>}
                                    </Translation>
                                    <Translation ns="header">
                                        {t => <div className={localStorage.getItem("websiteLang") == 'en' ? 'menu-item-en' : 'menu-item'} onClick={() => this.scrollToElement('philosophy')}>{t('philosophy')}</div>}
                                    </Translation>
                                    <Translation ns="header">
                                        {t => <div className={localStorage.getItem("websiteLang") == 'en' ? 'menu-item-en' : 'menu-item'} onClick={() => this.scrollToElement('contacts')}>{t('contacts')}</div>}
                                    </Translation>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Row style={{ position: 'initial' }}>
                    <div className='drop-down-menu' id='drop-down-menu'>
                        {AuthService.getCurrentUserId() == null ? <span> <div className='drop-down-itemLoginRegister'>
                            <Translation ns="header">
                                {t => <span onClick={() => window.location.href = '/register'}>{t('register')}</span>}
                            </Translation>
                        </div>
                        <div className='drop-down-itemLoginRegister'>
                            <Translation ns="header">
                                {t => <span onClick={() => window.location.href = '/login'}>{t('login')}</span>}
                            </Translation>
                        </div> </span>:
                        <Translation ns="header">
                        { t =>
                            <div className='drop-down-itemLoginRegister'>
                                <span onClick={() => {AuthService.logout(); window.location.href = '/home'}}>{t('popup.exit')}</span>
                            </div>
                        }
                        </Translation>

                        }
                        <div className='drop-down-item'>
                            <Translation ns="header">
                                {t => <span onClick={() => this.scrollToElement('')}>{t('home')}</span>}
                            </Translation>
                        </div>
                        <div className='drop-down-item'>
                            <Translation ns="header">
                                {t => <span onClick={() => this.scrollToElement('order-now')}>{t('orderNow')}</span>}
                            </Translation>
                        </div>
                        <div className='drop-down-item'>
                            <Translation ns="header">
                                {t => <span onClick={() => this.scrollToElement('what-do')}>{t('instructions')}</span>}
                            </Translation>
                        </div>
                        <div className='drop-down-item'>
                            <Translation ns="header">
                                {t => <span onClick={() => this.scrollToElement('company')}>{t('company')}</span>}
                            </Translation>
                        </div>
                        <div className='drop-down-item'>
                            <Translation ns="header">
                                {t => <span onClick={() => this.scrollToElement('philosophy')}>{t('philosophy')}</span>}
                            </Translation>
                        </div>
                        <div className='drop-down-item'>
                            <Translation ns="header">
                                {t => <span onClick={() => this.scrollToElement('contacts')}>{t('contacts')}</span>}
                            </Translation>
                        </div>
                    </div>
                </Row>



            </Container>
        )
    }
}

/**,
 * Maps state to props.
 * @param state the state.
 */
const mapStateToProps = (state) => {
    return {
        userData: state.userData,
        cart: state.cart,
        restaurant: state.goToCheckoutModal.selectedRestaurant
    };
};

export default withRouter(connect(mapStateToProps, {})(Header));
