import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Utility from '../utility'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {Translation} from "react-i18next";


/**
 * Dot divider component
 */
const DotDivider = () => {
    return (
        <div style={{ color: '#92C143', marginBottom: -5, letterSpacing: 2 }}>
            <b>
                &#183;&#183;&#183;&#183;&#183;&#183;&#183;&#183;&#183;&#183;&#183;&#183;&#183;&#183;&#183;&#183;&#183;&#183;
            </b>
        </div>
    )
}

/**
 * Render delivery hours.
 * @param hourObject the hour object.
 * @param fontSize the font size of hours.
 * @param sideBySide flag for if hours will be side by side.
 * @returns {*}
 */
const renderDeliveryHours = (hourObject, fontSize = 11, sideBySide = true) => {
    var hourList = hourObject.filter((item) => {
        return item.deliveryHoursId.dayNumber === Utility.getDayNumOfWeek() && item.startTime !== null && item.endTime !== null;
    })

    if (hourList === null || hourList.length === 0) {
        return (
            <h6 className="restaurant-info other-column" style={{ color: '#547200', marginTop: -8, paddingBottom: 15, fontSize: 11, fontFamily: 'MontserratMedium' }}>
                {'not available'}
            </h6>
        )
    }

    var splittedStart = hourList[0].startTime.split(":");
    splittedStart.pop();
    var startStatus = parseInt(splittedStart[0]) <= 11 ? 'am' : 'pm'

    var splittedEnd = hourList[0].endTime.split(":");
    splittedEnd.pop();
    var endStatus = parseInt(splittedEnd[0]) <= 11 ? 'am' : 'pm'


    return (
        <div>
            {sideBySide ?
                <h6 className="restaurant-info other-column" style={{ color: '#547200', marginTop: -8, paddingBottom: 15, fontSize: fontSize, fontFamily: 'MontserratMedium' }}>
                    {splittedStart.join(':')}{' ' + startStatus} - {splittedEnd.join(':')}{' ' + endStatus}
                </h6> :
                <div>
                    <h6 className="restaurant-info other-column" style={{ color: '#547200', marginTop: -8, fontSize: fontSize, fontFamily: 'MontserratMedium' }}>
                        {splittedStart.join(':')}{' ' + startStatus}
                    </h6>
                    <h6 className="restaurant-info other-column" style={{ color: '#547200', marginTop: -8, paddingBottom: 15, fontSize: fontSize, fontFamily: 'MontserratMedium' }}>
                        {splittedEnd.join(':')}{' ' + endStatus}
                    </h6>
                </div>}
        </div>
    )
}

/**
 * Render working hours.
 * @param hourObject hours object.
 * @param fontSize font size of the hours.
 * @param sideBySide flag for if they will be placed in separate lines or in same line.
 * @returns {*}
 */
const renderWorkingHours = (hourObject, fontSize = 11, sideBySide = true) => {
    var hourList = hourObject.filter((item) => {
        return item.workingHoursId.dayNumber === Utility.getDayNumOfWeek() && item.startTime !== null && item.endTime !== null;
    })

    if (hourList === null || hourList.length === 0) {
        return (
            <h6 className="restaurant-info other-column" style={{ color: '#547200', marginTop: -8, paddingBottom: 15, fontSize: 11, fontFamily: 'MontserratMedium' }}>
                {'not available'}
            </h6>
        )
    }

    var splittedStart = hourList[0].startTime.split(":");
    splittedStart.pop();
    var startStatus = parseInt(splittedStart[0]) <= 11 ? 'am' : 'pm'

    var splittedEnd = hourList[0].endTime.split(":");
    splittedEnd.pop();
    var endStatus = parseInt(splittedEnd[0]) <= 11 ? 'am' : 'pm'


    return (
        <div>
            {sideBySide ?
                <h6 className="restaurant-info other-column" style={{ color: '#547200', marginTop: -8, paddingBottom: 15, fontSize: fontSize, fontFamily: 'MontserratMedium' }}>
                    {splittedStart.join(':')}{' ' + startStatus} - {splittedEnd.join(':')}{' ' + endStatus}
                </h6>
                : <div>
                    <h6 className="restaurant-info other-column" style={{ color: '#547200', marginTop: -8, fontSize: fontSize, fontFamily: 'MontserratMedium' }}>
                        {splittedStart.join(':')}{' ' + startStatus}
                    </h6>
                    <h6 className="restaurant-info other-column" style={{ color: '#547200', marginTop: -8, paddingBottom: 15, fontSize: fontSize, fontFamily: 'MontserratMedium' }}>
                        {splittedEnd.join(':')}{' ' + endStatus}
                    </h6>
                </div>}
        </div>
    )
}

/**
 * Render day off
 * @param {*} workingHours
 */
const renderDayOff = (workingHours) => {

    var dayOff = [];
    if (workingHours !== undefined) {
        for (var i = 0; i < 7; i++) {
            var list = workingHours.filter((item) => {
                return item.workingHoursId.dayNumber === i;
            })
            if (list === null || list.length === 0 || (list !== null && list[0].startTime === null && list[0].endTime === null)) {
                dayOff.push(Utility.getDayName(i));
            }

        }
    }
    if (dayOff.length === 0) {
        dayOff.push('-');
    }
    return dayOff;
}

/**
 * Generate Google Maps url from restaurant address
 * @param {*} address
 */
const generateGoogleUrl = (address) => {
    var baseUrl = 'https://www.google.com/maps/place/'
    var words = address.split(" ")
    words.forEach(element => {
        baseUrl += '+'
        baseUrl += element
    })
    return baseUrl;
}
/**
 * Restaurant info modal component
 * @param {*} props
 */
const RestaurantInfo = (props) => {
    return (
        <Modal show={props.show} onClose={props.onClose} onHide={props.onHide} size={'lg'}>
            <FontAwesomeIcon onClick={props.onHide} icon={faTimes} size="md" color="#38383A" style={{ position: 'absolute', right: 10, top: 10, zIndex: 1, cursor: 'pointer' }} />
            <Modal.Body style={{ backgroundColor: '#E9F2D9', paddingLeft: '0px', paddingRight: '0px', paddingBottom: '0px' }}>
                <Container fluid>
                    <Row style={{ marginBottom: '15px', paddingLeft: 5, paddingRight: 5 }}>
                        <Col md={5} lg={5}>
                            <img className="restaurant-info" src={props.restaurant.logoUrl} style={{ maxHeight: '100px', maxWidth: '100px' }} />
                            <h4 className="restaurant-info" style={{ paddingTop: 40, color: '#4D4D4D', fontSize: 21, fontFamily: 'Montserrat-Bold' }}>
                                {props.restaurant.name}
                            </h4>
                            <h6 className="restaurant-info" style={{ color: '#6A8321', fontSize: 14, marginBottom: 0, fontFamily: 'MontserratMedium' }}>
                                {Utility.getStreet(props.restaurant.address) !== "" ? Utility.getStreet(props.restaurant.address) + "," : null}
                            </h6>

                            <h6 className="restaurant-info" style={{ color: '#6A8321', fontSize: 14, marginBottom: 0, fontFamily: 'MontserratMedium' }}>
                                {Utility.getCity(props.restaurant.address)}
                            </h6>
                            <h6 className="restaurant-info" style={{ color: '#6A8321', fontSize: 14, marginBottom: 0, fontFamily: 'MontserratMedium' }}>
                                {props.restaurant.phone}
                            </h6>
                            <div className="restaurant-info">
                                <div>
                                    <Translation ns="restaurant.info.modal">
                                        {t => (
                                            <Button className="restaurant-info shadow-none"
                                                    style={{
                                                        backgroundColor: '#F1BD36',
                                                        color: '#472800',
                                                        marginTop: 15,
                                                        marginBottom: 5,
                                                        borderRadius: 0,
                                                        borderWidth: 0,
                                                        boxShadow: 0,
                                                        paddingTop: 0,
                                                        paddingBottom: 0,
                                                        fontFamily: 'MontserratMedium',
                                                        fontSize: 14,
                                                    }}
                                                    onClick={
                                                        () => window.open(generateGoogleUrl(props.restaurant.address), '_blank')
                                                    }>
                                                {t('view.map').toUpperCase()}
                                            </Button>
                                        )}
                                    </Translation>
                                </div>
                            </div>
                        </Col>
                        <Col md={7} lg={7}>
                            <Row>
                                <Col md={6} lg={6}>
                                    <Translation ns="restaurant.info.modal">
                                        {t => (
                                            <h6 className="restaurant-info other-column" style={{ color: '#547200', marginBottom: -5, paddingTop: 15, fontSize: 11, fontFamily: 'MontserratMedium' }}>
                                                {t('working.hours')}
                                            </h6>
                                        )}
                                    </Translation>
                                    <h6 className="restaurant-info" >
                                        <DotDivider />
                                    </h6>

                                    {renderWorkingHours(props.restaurant.workingHours, 11, true)}
                                </Col>

                                <Col md={6} lg={6}>
                                    <Translation ns="restaurant.info.modal">
                                        {t => (
                                            <h6 className="restaurant-info other-column" style={{ color: '#547200', marginBottom: -5, paddingTop: 15, fontSize: 11, fontFamily: 'MontserratMedium' }}>
                                                {t('delivery.hours')}
                                            </h6>
                                        )}
                                    </Translation>
                                    <h6 className="restaurant-info" >
                                        <DotDivider />
                                    </h6>
                                    {renderDeliveryHours(props.restaurant.deliveryHours, 11, true)}
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} lg={6}>
                                    <Translation ns="restaurant.info.modal">
                                        {t => (
                                            <h6 className="restaurant-info other-column" style={{ color: '#547200', marginBottom: -5, paddingTop: 15, fontSize: 11, fontFamily: 'MontserratMedium' }}>
                                                {t('closed.days')}
                                            </h6>
                                        )}
                                    </Translation>
                                    <h6 className="restaurant-info" >
                                        <DotDivider />
                                    </h6>
                                    {renderDayOff(props.restaurant.workingHours).map((item) => {
                                        return (
                                            <h6 className="restaurant-info other-column" style={{ color: '#547200', marginTop: -8, fontSize: 11, fontFamily: 'MontserratMedium' }}>
                                                {item}
                                            </h6>
                                        )
                                    })}

                                </Col>

                                <Col md={6} lg={6} sm={12} xs={12}>
                                    <Row>
                                        <Col md={12} lg={12} style={{paddingBottom: 20}}>
                                            <Translation ns="restaurant.info.modal">
                                                {t => (
                                                    <h6 className="restaurant-info other-column" style={{ color: '#547200', marginBottom: -5, paddingTop: 15, fontSize: 11, fontFamily: 'MontserratMedium' }}>
                                                        {t('delivery.cost')}
                                                    </h6>
                                                )}
                                            </Translation>
                                            <h6 className="restaurant-info" >
                                                <DotDivider />
                                            </h6>
                                            {props.restaurant.deliveryCost === null ?
                                                <h6 className="restaurant-info other-column" style={{ color: '#547200', marginTop: -8, fontSize: 11, fontFamily: 'MontserratMedium' }}>
                                                    -
                                                </h6>
                                                :
                                                <h6 className="restaurant-info other-column" style={{ color: '#547200', marginTop: -8, fontSize: 11, fontFamily: 'MontserratMedium' }}>
                                                    {props.restaurant.deliveryCost}{' €'}
                                                </h6>
                                            }
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={12} lg={12}>
                                            <Translation ns="restaurant.info.modal">
                                                {t => (
                                                    <h6 className="restaurant-info other-column" style={{ color: '#547200', marginBottom: -5, paddingTop: 15, fontSize: 11, fontFamily: 'MontserratMedium' }}>
                                                        {t('delivery.time')}
                                                    </h6>
                                                )}
                                            </Translation>
                                            <h6 className="restaurant-info" >
                                                <DotDivider />
                                            </h6>
                                            {props.restaurant.deliveryTime === null ?
                                                <h6 className="restaurant-info other-column" style={{ color: '#547200', marginTop: -8, fontSize: 11, fontFamily: 'MontserratMedium'  }}>
                                                    -
                                                </h6>
                                                :
                                                <h6 className="restaurant-info other-column" style={{ color: '#547200', marginTop: -8, fontSize: 11, fontFamily: 'MontserratMedium'  }}>
                                                    {props.restaurant.deliveryTime}{' min'}
                                                </h6>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{ backgroundColor: '#92c143' }}>
                        <Col style={{
                            paddingLeft: 9,
                            paddingRight: 9,
                            paddingTop: 25,
                            paddingBottom: 25,
                            color: 'white',
                            fontFamily: 'MontserratRegular',
                            fontSize: 12
                        }}>
                            {props.restaurant.description}
                        </Col>
                    </Row>
                    <Row style={{backgroundColor: '#92C143'}} className={'d-none d-lg-flex'}>
                        <Col lg={4} style={{padding: 0}}>
                            <img src={props.restaurant.imageUrl} style={{ width: '99%'}} />
                        </Col>
                        <Col lg={4} style={{padding: 0, textAlign: 'center'}}>
                            <img src={props.restaurant.imageUrl} style={{ width: '99%'}} />
                        </Col>
                        <Col lg={4} style={{padding: 0}}>
                            <img src={props.restaurant.imageUrl} style={{ width: '99%', position: 'absolute', right: 0}} />
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    )
}

/**
 * Restaurant header component
 */
class RestaurantHeader extends React.Component {
    constructor(props) {
        super();
        this.state = { restaurant: null, openModal: false }
    }
    componentDidMount = async () => {
        this.setState({ restaurant: this.props.restaurant })
    }


    render() {

        return (
            this.state.restaurant != null ?
                <React.Fragment>
                    <Container fluid className={'restaurant-header-img-container'} style={{backgroundImage: `url("` + this.state.restaurant.headingImageUrl + `")`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                        <Row className="justify-content-center" style={{
                            background: 'rgba(229,229,229,0.9)',
                            marginLeft: 5,
                            width: 235,
                            paddingBottom: 10
                        }}>
                            <div>
                                <Row className="justify-content-center">
                                    <img src={this.state.restaurant.logoUrl} style={{ maxHeight: 135, maxWidth: 135, paddingTop: 20 }} />
                                </Row>
                                <Row className="justify-content-center">
                                    <Translation ns="restaurant">
                                        {t => (
                                            <Button
                                                className="shadow-none"
                                                variant="link"
                                                onClick={() => this.setState({ openModal: true })} style={{ color: '#E57A2A', boxShadow: 0 }}>
                                                <u style={{ fontFamily: 'MontserratMedium', fontSize: 19 }}>
                                                    {t('more.info')}
                                                </u>
                                            </Button>
                                        )}
                                    </Translation>
                                </Row>
                            </div>
                        </Row>
                        <RestaurantInfo show={this.state.openModal} onHide={() => this.setState({ openModal: false })} restaurant={this.state.restaurant} />
                    </Container>
                    <Container style={{marginTop: 35}}>
                        <div style={{background: 'rgba(229,229,229,1)', padding: 20}}>
                            <Row>
                                <Col xs={12} sm={12} lg={4}>
                                    <h4 className="restaurant-info" style={{paddingLeft: 10}}>
                                        <b style={{ color: '#4D4D4D', fontSize: 36, fontFamily: 'Montserrat-Bold' }}>
                                            {this.state.restaurant.name}
                                        </b>
                                    </h4>
                                </Col>
                            </Row>
                            <Row style={{ paddingTop: 30 }} className="justify-content-center">
                                <Col xs={12} sm={12} md={12} lg={3} xl={3} >
                                    <div style={{paddingLeft: 10}}>
                                        <h6 className="restaurant-info" style={{ color: '#6A8321', marginBottom: 0, fontSize: 18, fontFamily: 'MontserratMedium' }}>
                                            {Utility.getStreet(this.state.restaurant.address) !== "" ? Utility.getStreet(this.state.restaurant.address) + "," : null}
                                        </h6>
                                        <h6 className="restaurant-info" style={{ color: '#6A8321', marginBottom: 0, fontSize: 18, fontFamily: 'MontserratMedium' }}>
                                            {Utility.getCity(this.state.restaurant.address)}
                                        </h6>
                                        <h6 className="restaurant-info" style={{ color: '#6A8321', marginBottom: 0, fontSize: 18, fontFamily: 'MontserratMedium' }}>
                                            {this.state.restaurant.phone}
                                        </h6>
                                    </div>
                                    <div className="restaurant-info">
                                        <div>
                                            <Translation ns="restaurant.info.modal">
                                                {t => (
                                                    <Button className="restaurant-info shadow-none"
                                                            style={{
                                                                backgroundColor: '#F1BD36',
                                                                color: '#472800',
                                                                marginTop: 15,
                                                                borderRadius: 0,
                                                                borderWidth: 0,
                                                                boxShadow: 0,
                                                                paddingTop: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 22,
                                                                paddingRight: 22,
                                                                fontFamily: 'MontserratMedium',
                                                                fontSize: 19,
                                                            }}
                                                            onClick={
                                                                () => window.open(generateGoogleUrl(this.state.restaurant.address), '_blank')
                                                            }>
                                                        {t('view.map').toUpperCase()}
                                                    </Button>
                                                )}
                                            </Translation>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={9} xl={9} className={'pt-4 pt-lg-0'}>
                                    <Row className="justify-content-center">
                                        <Col xs={6} sm={6} md={3} lg={2}>
                                            <Translation ns="restaurant.info.modal">
                                                {t => (
                                                    <h6 className="restaurant-info other-column" style={{ color: '#6A8321', marginBottom: -5, fontSize: 14, fontFamily: 'MontserratMedium' }}>
                                                        {t('working.hours')}
                                                    </h6>
                                                )}
                                            </Translation>
                                            <h6 className="restaurant-info" >
                                                <DotDivider />
                                            </h6>
                                            {renderWorkingHours(this.state.restaurant.workingHours, 14, false)}
                                        </Col>
                                        <Col xs={0} sm={0} md={0} lg={1} className="d-none d-lg-block" />
                                        <Col xs={6} sm={6} md={3} lg={2}>
                                            <Translation ns="restaurant.info.modal">
                                                {t => (
                                                    <h6 className="restaurant-info other-column" style={{ color: '#6A8321', marginBottom: -5, fontSize: 14, fontFamily: 'MontserratMedium' }}>
                                                        {t('delivery.hours')}
                                                    </h6>
                                                )}
                                            </Translation>
                                            <h6 className="restaurant-info">
                                                <DotDivider />
                                            </h6>
                                            {renderDeliveryHours(this.state.restaurant.deliveryHours, 14, false)}
                                        </Col>
                                        <Col xs={0} sm={0} md={0} lg={1} className="d-none d-lg-block" />
                                        <Col xs={6} sm={6} md={3} lg={2}>
                                            <Translation ns="restaurant.info.modal">
                                                {t => (
                                                    <h6 className="restaurant-info other-column" style={{ color: '#6A8321', marginBottom: -5, fontSize: 14, fontFamily: 'MontserratMedium' }}>
                                                        {t('delivery.cost')}
                                                    </h6>
                                                )}
                                            </Translation>
                                            <h6 className="restaurant-info">
                                                <DotDivider />
                                            </h6>
                                            <h6 className="restaurant-info other-column" style={{ color: '#547200', marginTop: -8, paddingBottom: 15, fontSize: 14, fontFamily: 'MontserratMedium' }}>
                                                {this.state.restaurant.deliveryCost !== null ? this.state.restaurant.deliveryCost + ' €'
                                                    : '-'
                                                }
                                            </h6>
                                        </Col>
                                        <Col xs={0} sm={0} md={0} lg={1} className="d-none d-lg-block" />
                                        <Col xs={6} sm={6} md={3} lg={2}>
                                            <Translation ns="restaurant.info.modal">
                                                {t => (
                                                    <h6 className="restaurant-info other-column" style={{ color: '#6A8321', marginBottom: -5, fontSize: 14, fontFamily: 'MontserratMedium' }}>
                                                        {t('delivery.time')}
                                                    </h6>
                                                )}
                                            </Translation>
                                            <h6 className="restaurant-info">
                                                <DotDivider />
                                            </h6>
                                            <h6 className="restaurant-info other-column" style={{ color: '#547200', marginTop: -8, paddingBottom: 15, fontSize: 14, fontFamily: 'MontserratMedium' }}>
                                                {this.state.restaurant.deliveryTime !== null ? this.state.restaurant.deliveryTime + ' min'
                                                    : '-'
                                                }
                                            </h6>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </React.Fragment>
                : null
        )
    }
}

export default RestaurantHeader;
