import React from 'react';
import { Container, Row, Col, Form, Button, Image, Alert } from "react-bootstrap";
import './Cart.css';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation, Translation } from "react-i18next";
import "./OrderSubmit.css"
import "./Cart.css"
import { setCart } from "../actions";
import { renderToString } from 'react-dom/server';
import Logo from '../assets/image/logo.png'
import Utility from '../utility';

/**
 * Order submit page.
 */
class OrderSubmit extends React.Component {

    componentDidMount() {
        var filtered = this.props.cart.cartContent.filter(function () {
            return false;
        });
        this.props.setCart(filtered);
        /*Following if is a workout to prevent the page crash
        after refreshing the page because loosing data in redux store.*/
        if (!this.props.checkout.orderDetails) {
            window.location.href = '/';
        }
    }

    /**
     * Creates and returns a printable div.
     * @param orderDetails the order details.
     * @returns {*} the translation component.
     */
    printableDiv = (orderDetails) => {
        if (orderDetails) {
            var total = orderDetails.orderPrice;
            if (orderDetails.orderDeliveryStatus) {
                total += orderDetails.deliveryPrice;
            }
            if (orderDetails.orderDiscount) {
                total -= orderDetails.orderDiscount;
            }
            return(
                <Translation ns="print.order">
                    {t => (
                        <div>
                            <div style={{width: '100%', textAlign: 'center', paddingBottom: '25px', color: 'black',}}>
                                <div style={{paddingTop: 70, paddingBottom: 70}}><Image src={Logo} style={{height: 100}}/></div>
                                <div style={{
                                    fontSize: 36,
                                    borderBottom: "1px solid #dddddd",
                                    fontFamily: 'MontserratMedium',
                                    paddingBottom: 5
                                }}>{t('title')}</div>
                                <div style={{
                                    fontSize: 24,
                                    borderBottom: "1px solid #dddddd",
                                    fontFamily: 'MontserratRegular',
                                    paddingTop: 25,
                                    paddingBottom: 25
                                }}>
                                    {t('order.id') + ": " + orderDetails.id}
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    {t('date') + ": " + Utility.parseIsoDatetime(orderDetails.orderCreationDate)}
                                </div>
                                <div style={{
                                    fontSize: 24,
                                    borderBottom: "1px solid #dddddd",
                                    paddingTop: 30,
                                    paddingBottom: 40,
                                    display: 'flex'
                                }}>
                                    <div style={{width: '50%'}}>
                                        <div style={{float: 'left', textAlign: 'left'}}>
                                            <p style={{fontFamily: 'MontserratMedium', margin: 0, fontSize: 26}}>{t('restaurant.info')}</p>
                                            <p style={{fontFamily: 'MontserratRegular', margin: 0}}>{orderDetails.restaurantName}</p>
                                            <p style={{fontFamily: 'MontserratRegular', margin: 0}}>{orderDetails.restaurantAddress}</p>
                                            <p style={{fontFamily: 'MontserratRegular', margin: 0}}>{orderDetails.restaurantPhone}</p>
                                        </div>
                                    </div>
                                    <div style={{width: '50%'}}>
                                        <div style={{float: 'right', textAlign: 'left'}}>
                                            <p style={{fontFamily: 'MontserratMedium', margin: 0, fontSize: 26}}>{t('client.info')}</p>
                                            <p style={{fontFamily: 'MontserratRegular', margin: 0}}>{t('client.name') + ': ' + orderDetails.contacts.contactName}</p>
                                            <p style={{fontFamily: 'MontserratRegular', margin: 0}}>{t('client.surname') + ': '+ orderDetails.contacts.contactSurname}</p>
                                        </div>
                                    </div>
                                </div>
                                <div style={{
                                    fontSize: 24,
                                    borderBottom: "1px solid #dddddd",
                                    paddingTop: 30,
                                    paddingBottom: 30,
                                }}>
                                    <div style={{textAlign: 'left'}}>
                                        <p style={{fontFamily: 'MontserratMedium', margin: 0, fontSize: 26}}>{t('order.type.title')}</p>
                                        {
                                            orderDetails.orderDeliveryStatus ?
                                                <div>
                                                    <p style={{fontFamily: 'MontserratRegular', margin: 0}}>{t('delivery.home')}</p>
                                                    <p style={{fontFamily: 'MontserratRegular', margin: 0}}>{t('delivery.time') + ': ' + Utility.formatPickupDeliveryTime(orderDetails.orderDeliverytime)}</p>
                                                    <p style={{fontFamily: 'MontserratRegular', margin: 0}}>{t('address') + ': ' + orderDetails.contacts.deliveryAddress}</p>
                                                </div>
                                                :
                                                <div>
                                                    <p style={{fontFamily: 'MontserratRegular', margin: 0}}>{t('delivery.pickup')}</p>
                                                    <p style={{fontFamily: 'MontserratRegular', margin: 0}}>{t('pickup.time') + ': '+ Utility.formatPickupDeliveryTime(orderDetails.orderDeliverytime)}</p>
                                                </div>
                                        }
                                        <p style={{fontFamily: 'MontserratRegular', margin: 0}}>{t('payment.method') + ': '} {orderDetails.paymentType === 0 ? t('payment.type.cash') : t('payment.type.card')}</p>
                                    </div>
                                </div>
                                <div style={{
                                    borderBottom: "1px solid #dddddd",
                                    paddingTop: 60,
                                    paddingBottom: 10,
                                }}>
                                    <div style={{textAlign: 'left'}}>
                                        <div style={{display: 'flex', fontFamily: 'MontserratMedium', fontSize: 26}}>
                                            <div style={{width: '50%'}}>
                                                <p style={{margin: 0}}>{t('product')}</p>
                                            </div>
                                            <div style={{width: '50%', display: 'flex'}}>
                                                <div style={{width: '50%'}}>
                                                    <p style={{margin: 0}}>{t('addons')}</p>
                                                </div>
                                                <div style={{width: '50%', display: 'flex', paddingLeft: 10}}>
                                                    <div style={{width: '50%'}}>
                                                        <p style={{margin: 0}}>{t('quantity')}</p>
                                                    </div>
                                                    <div style={{width: '50%', textAlign: 'right'}}>
                                                        <p style={{margin: 0}}>{t('price')}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {orderDetails.dishes.map(dish => {
                                            return (
                                                <div style={{display: 'flex', fontFamily: 'MontserratRegular', fontSize: 24}}>
                                                    <div style={{width: '50%'}}>
                                                        <p style={{marginBottom: 0}}>{dish.dishName}</p>
                                                    </div>
                                                    <div style={{width: '50%', display: 'flex'}}>
                                                        <div style={{width: '50%'}}>
                                                            <p style={{marginBottom: 0}}>{Utility.formatAddonsOfDish(dish)}</p>
                                                        </div>
                                                        <div style={{width: '50%', display: 'flex', paddingLeft: 10}}>
                                                            <div style={{width: '50%'}}>
                                                                <p style={{marginBottom: 0}}>{dish.dishesQuantity}</p>
                                                            </div>
                                                            <div style={{width: '50%', textAlign: 'right'}}>
                                                                <p style={{margin: 0}}>{(dish.dishesQuantity * dish.dishPrice).toFixed(2)} €</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        {orderDetails.orderDiscount ?
                                            <div style={{display: 'flex', fontSize: 24}}>
                                                <div style={{width: '50%'}}>
                                                    <p style={{fontFamily: 'MontserratRegular', margin: 0}}>{t('discount.price')}</p>
                                                </div>
                                                <div style={{width: '50%', textAlign: 'right'}}>
                                                    <p style={{fontFamily: 'MontserratRegular', margin: 0}}>-{orderDetails.orderDiscount.toFixed(2)} €</p>
                                                </div>
                                            </div>
                                            : null
                                        }
                                        {orderDetails.orderDeliveryStatus ?
                                            <div style={{display: 'flex', fontSize: 24}}>
                                                <div style={{width: '50%'}}>
                                                    <p style={{fontFamily: 'MontserratRegular', margin: 0}}>{t('delivery.price')}</p>
                                                </div>
                                                <div style={{width: '50%', textAlign: 'right'}}>
                                                    <p style={{fontFamily: 'MontserratRegular', margin: 0}}>{orderDetails.deliveryPrice.toFixed(2)} €</p>
                                                </div>
                                            </div>
                                            : null
                                        }
                                        <div style={{display: 'flex', fontSize: 26}}>
                                            <div style={{width: '50%'}}>
                                                <p style={{fontFamily: 'MontserratMedium', margin: 0}}>{t('total')}</p>
                                            </div>
                                            <div style={{width: '50%', textAlign: 'right'}}>
                                                <p style={{fontFamily: 'MontserratMedium', margin: 0}}>{total.toFixed(2)} €</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Translation>
            );
        }
    };

    /**
     * Printable content will appear only in print screen.
     * @returns {*}
     */
    printableContent = () => {
        var body = document.getElementsByTagName("BODY")[0];
        var node = document.getElementById("printableContent");
        if (node == null) {
            node = document.createElement("DIV");
            node.setAttribute("id", "printableContent");
            node.setAttribute("class", "printable");
            node.innerHTML= renderToString(this.printableDiv(this.props.checkout.orderDetails));
            body.appendChild(node);
        } else {
            node.innerHTML = renderToString(this.printableDiv(this.props.checkout.orderDetails));
        }
        return null;
    };

    /**
     * Custom print action to set file name.
     * It's a workaround that changes title of the page until print action is done.
     */
    printAction = (orderCreationDate) => {
        const tempTitle = document.title;
        const appLang = localStorage.getItem('websiteLang');

        var fileName = "Vvfood";
        if (appLang && appLang === 'en') {
            fileName += "_order_date_";
        } else {
            fileName += "_data_ordine_";
        }
        fileName += Utility.parseIsoDate(orderCreationDate) + ".pdf";

        document.title = fileName;
        window.print();
        document.title = tempTitle;
    };

    /**
     * Renders order submit content.
     * @returns {*}
     */
    renderOrderSubmit = () => {
        return (
            <Translation ns="order.submit">
                {t =>
                    <Container>
                        <Row className='justify-content-center'>
                            <Col md={12} style={{ textAlign: 'center', marginTop: '70px' }}>
                                <div style={{ fontFamily: "MontserratMedium", color: '#557627', fontSize: '36px', marginBottom: '20px' }}>{t("orderProcessed")}</div>
                                <div style={{ fontFamily: "MontserratMedium", color: '#565751', fontSize: '25px', marginBottom: '50px' }}>{t("orderSummary")}</div>
                            </Col>
                        </Row>
                        <Row className='justify-content-center'>
                            <Col md={12} lg={11}>
                                <Row className='justify-content-center'>
                                    <Col md={6} className="submitLeftColumn">
                                        <Row>
                                            <Col md={12} style={{ textAlign: 'center' }}>
                                                <div style={{ fontFamily: "MontserratMedium", color: '#565751', fontSize: '25px', marginBottom: '35px' }}>
                                                    {this.props.checkout.orderDetails ? this.props.checkout.orderDetails.orderDeliveryStatus ?
                                                        t("deliveryDetails") : t("pickupDetails")
                                                        : null}
                                                </div>
                                            </Col>
                                            <Col md={12}>
                                                <Form.Row style={{ paddingTop: 10 }}>
                                                    <Form.Group as={Col} sm={12} md={12} controlId="formGridEmail" style={{ marginBottom: '10px' }}>
                                                        <Form.Control className="orderLabel" type="text" style={{ height: 'initial', paddingTop: '9px', paddingBottom: '9px', paddingLeft: '5px', marginTop: -8, fontFamily: "MontserratMedium" }} value={this.props.checkout.orderDetails ? this.props.checkout.orderDetails.contacts.contactName : null} disabled />
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row style={{ paddingTop: 10 }}>
                                                    <Form.Group as={Col} sm={12} md={12} controlId="formGridPassword" style={{ marginBottom: '10px' }}>
                                                        <Form.Control className="orderLabel" type="text" style={{ height: 'initial', paddingTop: '9px', paddingBottom: '9px', paddingLeft: '5px', marginTop: -8, fontFamily: "MontserratMedium" }} value={this.props.checkout.orderDetails ? this.props.checkout.orderDetails.contacts.contactSurname : null} disabled />
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row style={{ paddingTop: 10 }}>
                                                    <Form.Group as={Col} sm={12} md={12} controlId="formGridEmail" style={{ marginBottom: '10px' }}>
                                                        <Form.Control className="orderLabel" type="text" style={{ height: 'initial', paddingTop: '9px', paddingBottom: '9px', paddingLeft: '5px', marginTop: -8, fontFamily: "MontserratMedium" }} value={this.props.checkout.orderDetails ? this.props.checkout.orderDetails.contacts.contactPhoneNumber : null} disabled />
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row style={{ paddingTop: 10 }}>
                                                    <Form.Group as={Col} sm={12} md={12} controlId="formGridPassword" style={{ marginBottom: '10px' }}>
                                                        <Form.Control className="orderLabel" type="text" style={{ height: 'initial', paddingTop: '9px', paddingBottom: '9px', paddingLeft: '5px', marginTop: -8, fontFamily: "MontserratMedium" }} value={this.props.checkout.orderDetails ? this.props.checkout.orderDetails.contacts.contactEmail : null} disabled />
                                                    </Form.Group>
                                                </Form.Row>
                                                {this.props.checkout.orderDetails ? this.props.checkout.orderDetails.orderDeliveryStatus ?
                                                    <Form.Row style={{ paddingTop: 10 }}>
                                                        <Form.Group as={Col} sm={12} md={12} controlId="formGridEmail" style={{ marginBottom: '10px' }}>
                                                            <Form.Control className="orderLabel" type="text" style={{ height: 'initial', paddingTop: '9px', paddingBottom: '9px', paddingLeft: '5px', marginTop: -8, fontFamily: "MontserratMedium" }} value={this.props.checkout.orderDetails ? this.props.cart.deliveryAddress : null} disabled />
                                                        </Form.Group>
                                                    </Form.Row> : null : null}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={6} className="submitRightColumn">
                                        <div style={{ padding: '7px 20px', backgroundColor: 'white', border: '2px solid #557627', margin: '1px 0', display: 'flex', minHeight: '120px', textAlign: 'right', alignItems: 'center', paddingLeft: '30px', paddingRight: '30px', paddingTop: '20px', paddingBottom: '20px' }}>
                                            <div style={{ color: '#4f6d37', fontSize: '22px', width: '50%', textAlign: 'left', fontFamily: 'MontserratLight', fontWeight: 'bold' }}>{this.props.checkout.orderDetails ? this.props.checkout.orderDetails.orderDeliveryStatus ? t("delivery") : t("pickup") : null}</div>
                                            <div style={{ width: '50%', textAlign: 'right' }}>
                                                <div className={'pick-up-time-container'} style={{ width: '40%', backgroundColor: '#92c143' }}>
                                                    <select className={'pick-up-time-select'} value={1} disabled style={{ minHeight: '35px', fontFamily: 'Montserrat-SemiBold', letterSpacing: '2px', fontSize: '20px' }}>
                                                        <option>{this.props.checkout.orderDetails ? this.props.checkout.orderDetails.orderDeliverytime.substring(0, 2) : null}</option>
                                                    </select>
                                                </div>
                                                <div className={'pick-up-time-container'} style={{ width: '40%', backgroundColor: '#92c143' }}>
                                                    <select className={'pick-up-time-select'} value={"1"} disabled style={{ minHeight: '35px', fontFamily: 'Montserrat-SemiBold', letterSpacing: '2px', fontSize: '20px' }}>
                                                        <option>{this.props.checkout.orderDetails ? this.props.checkout.orderDetails.orderDeliverytime.substring(3, 5) : null}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        {this.props.checkout.orderDetails ?
                                            this.props.checkout.orderDetails.dishes.map((obj) => {
                                                return <div style={{ marginTop: '3px', borderTop: '2 solid white', backgroundColor: '#557627', minHeight: 40, paddingLeft: '30px', paddingRight: '30px', paddingTop: '13px', paddingBottom: '13px' }}>
                                                    <div style={{ display: 'inline-block', width: '100%', verticalAlign: 'middle' }}>
                                                        <div style={{ width: '50%', display: 'inline-block', marginRight: '2%', fontFamily: "Montserrat-SemiBold", color: "white" }}>
                                                            {obj.dishName}
                                                        </div>
                                                        <div style={{ width: '10%', display: 'inline-block', marginRight: '2%', fontFamily: "Montserrat-SemiBold", color: "white" }}>
                                                            {"x" + obj.dishesQuantity}
                                                        </div>
                                                        <div style={{ width: '35%', display: 'inline-block', textAlign: 'right', fontFamily: "Montserrat-SemiBold", color: "white" }}>
                                                            {parseFloat(obj.dishPrice).toFixed(2) + " €"}
                                                        </div>
                                                        {obj.addons.map((addon) => {
                                                            return (
                                                                <div className="checkout-addon">
                                                                    <div style={{ width: "50%", display: "inline-block", marginRight: "2%" }}>{`+ ${addon.dishAddonName}`}</div>
                                                                    <div style={{ width: "10%", display: "inline-block", marginRight: "2%" }}>{`x${obj.dishesQuantity}`}</div>
                                                                    <div style={{
                                                                        width: "35%",
                                                                        display: "inline-block",
                                                                        textAlign: "right",
                                                                    }}>{`${parseFloat(addon.price).toFixed(2)} €`}</div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>

                                                </div>
                                            })
                                            : null
                                        }
                                        <div style={{ marginTop: '3px' }}>
                                            <div style={{ backgroundColor: '#92c143', paddingTop: '20px', paddingBottom: '20px', paddingLeft: '30px', paddingRight: '30px' }}>
                                                <div style={{ backgroundColor: 'white' }}>
                                                    <p style={{
                                                        fontFamily: 'Montserrat-SemiBold',
                                                        color: '#aee64f',
                                                        width: '100%',
                                                        fontSize: 18,
                                                        paddingTop: 5,
                                                        paddingLeft: 5,
                                                        marginBottom: '0px',
                                                        cursor: 'default'
                                                    }}>
                                                        Note
                                            </p>
                                                </div>
                                                <div>
                                                    <textarea
                                                        maxLength={300}
                                                        readOnly
                                                        style={{
                                                            color: '#aee64f',
                                                            border: 0,
                                                            width: '100%',
                                                            outline: 0,
                                                            cursor: 'default'
                                                        }}
                                                        value={this.props.checkout.orderDetails ? this.props.checkout.orderDetails.orderComment : null}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ marginTop: '3px', border: 2, borderColor: 'white', backgroundColor: '#92c143', minHeight: 40, paddingLeft: '30px', paddingRight: '30px', paddingTop: '20px', paddingBottom: '20px' }}>
                                            <div className="submitPrice" style={{ backgroundColor: 'white', color: '#557627', marginBottom: 5, padding: '5px 5px', fontFamily: "Montserrat-SemiBold" }}>
                                                {t("subTotal")}
                                                <span style={{ display: 'inline', float: 'right' }}>{this.props.checkout.orderDetails ? this.props.checkout.orderDetails.orderPrice.toFixed(2) + " €" : null}</span>
                                            </div>
                                            {this.props.checkout.orderDetails && this.props.checkout.orderDetails.orderDiscount ?
                                                <div className="submitPrice" style={{
                                                    backgroundColor: 'white',
                                                    color: '#557627',
                                                    marginBottom: 5,
                                                    padding: '5px 5px',
                                                    fontFamily: "Montserrat-SemiBold"
                                                }}>
                                                    {t("discount")}
                                                    <span style={{
                                                        display: 'inline',
                                                        float: 'right'
                                                    }}>-{this.props.checkout.orderDetails.orderDiscount.toFixed(2) + " €"}</span>
                                                </div> : null
                                            }
                                            {this.props.checkout.orderDetails ? this.props.checkout.orderDetails.orderDeliveryStatus ? <div className="submitPrice" style={{ backgroundColor: 'white', color: '#557627', marginBottom: 5, padding: '5px 5px', fontFamily: "Montserrat-SemiBold" }}>
                                                {t("deliveryPrice")}
                                                <span style={{ display: 'inline', float: 'right' }}>{this.props.checkout.orderDetails ? this.props.checkout.orderDetails.deliveryPrice.toFixed(2) + " €" : null}</span>
                                            </div> : null : null}
                                            <div className="submitPrice" style={{ fontSize: '22px', backgroundColor: 'white', color: '#df3d2f', marginBottom: 5, padding: '5px 5px', fontFamily: "Montserrat-Bold" }}>
                                                <span>
                                                    {t("total")}
                                                    <span className="vatText"> {t("vat")}</span>
                                                </span>
                                                <span style={{ display: 'inline', float: 'right' }}>{
                                                    parseFloat((
                                                        this.props.checkout.orderDetails ? this.props.checkout.orderDetails.orderPrice : null)
                                                        + (this.props.checkout.orderDetails ? this.props.checkout.orderDetails.deliveryPrice : null)
                                                        - (this.props.checkout.orderDetails ? this.props.checkout.orderDetails.orderDiscount : null)).toFixed(2) + " €"
                                                }</span>
                                            </div>
                                        </div>
                                        <div>
                                            <Button variant="primary" type="button" className="orangeButton" onClick={() => this.printAction(this.props.checkout.orderDetails.orderCreationDate)}>
                                                {t("printOrder")}
                                            </Button>
                                        </div>
                                        <Link to={"/"}>
                                            <div style={{ fontFamily: "MontserratLight", textAlign: 'center', marginBottom: '70px', marginTop: '10px', color: '#47494f', textDecoration: 'underline' }}>
                                                <span>{t("comeBack")}</span>
                                            </div>
                                        </Link>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                }
            </Translation>
        )
    };

    render() {
        return (
            <Container>
                {this.renderOrderSubmit()}
                {this.printableContent()}
            </Container>
        );
    }

}

/**,
 * Maps state to props.
 * @param state the state.
 */
const mapStateToProps = (state) => {
    return {
        checkout: state.checkout,
        cart: state.cart,
    };
};

export default connect(mapStateToProps, { setCart })(withTranslation()(OrderSubmit));
